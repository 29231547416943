/* @flow */
import Analytic from '../../util/Analytic';
import stripe from './../PurchaseUtils';
import { isRedeem, purchaseStripeSuccess } from '../';
import {
  STRIPE_MONTHY,
  STRIPE_YEARLY,
  STRIPE_LIFETIME,
  PROMO_NAME
} from '../../shop/constants';
export const redirect = (product: string, isDev: boolean) => {
  console.log('Is Dev is', isDev);
  return `https://play.ling-app.com/${
    isDev ? '' : 'learn/'
  }payment/${product}/{CHECKOUT_SESSION_ID}`;
};

export const redirectWithPromo = (
  product: string,
  isDev: boolean,
  checkoutId: string
) => {
  // eslint-disable-next-line
  const promoName = localStorage.getItem(PROMO_NAME) || '';
  window.location.replace(
    `${document.location.origin}/${
      isDev ? '' : 'learn/'
    }payment/${product}/${checkoutId}/${promoName}`
  );
};

export const purchaseFailed = () =>
  window.location.replace('http://play.ling-app.com/');

export const redirectLogin = (
  product: string,
  isDev: boolean,
  currency: string,
  CHECKOUT_SESSION_ID: string,
  cardholderEmail: string
) => {
  if (product === STRIPE_MONTHY) {
    Analytic.logGA(Analytic.key.A12, {
      category: Analytic.key.A12,
      action: 'Check',
      label: currency
    });
  } else if (product === STRIPE_YEARLY) {
    Analytic.logGA(Analytic.key.A13, {
      category: Analytic.key.A13,
      action: 'Check',
      label: currency
    });
  } else if (product === STRIPE_LIFETIME) {
    Analytic.logGA(Analytic.key.A14, {
      category: Analytic.key.A14,
      action: 'Check',
      label: currency
    });
  }
  return window.location.replace(
    `${document.location.origin}/${
      isDev ? '' : 'learn/'
    }claim/${product}/${CHECKOUT_SESSION_ID}?cardholderEmail=${cardholderEmail}`
  );
};

export const redirectToStripe = (
  currency: string,
  functions: string,
  updateUserData: Function,
  history: Object,
  isDev: boolean,
  user: Object,
  itemInfo: Object[],
  redeemId: string,
  productName: string
) => {
  const { facebook, apple, google, email } = user;

  let userEmail = '';
  if (facebook) {
    userEmail = facebook.email;
  } else if (google) {
    userEmail = google.email;
  } else if (email) {
    userEmail = email.email;
  } else if (apple) {
    userEmail = apple.email;
  }
  if (isRedeem(redeemId)) {
    stripe
      .redirectToCheckout({
        items: itemInfo,
        customerEmail: userEmail,
        // Do not rely on the redirect to the successUrl for fulfilling
        // purchases, customers may not always reach the success_url after
        // a successful payment.
        // Instead use one of the strategies described in
        // https://stripe.com/docs/payments/checkout/fulfillment
        successUrl: `${redirect(`offer_${productName}`, isDev, currency)}`,
        cancelUrl: `${purchaseFailed()}`
      })
      .then(function(result) {
        const { error } = result;
        if (error) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer.
          alert(error.message);
        }
      });
  } else {
    if (
      redeemId !== 'monthly' &&
      redeemId !== 'yearly' &&
      redeemId !== 'lifetime'
    ) {
      purchaseStripeSuccess(functions, user, history, redeemId, updateUserData);
    }
  }
};

export const redirectProfileSceneAfterPurchased = (
  history: Object,
  product: string,
  id: string,
  isDev: boolean,
  currency: string
) => {
  if (product === STRIPE_YEARLY) {
    Analytic.logGA(Analytic.key.A13, {
      category: Analytic.key.A13,
      action: 'Check',
      label: currency
    });
  }

  history.push(`/${isDev ? '' : 'learn/'}payment/${product}/${id} `, {
    source: history.location.state.source
  });
};
