/* @flow */

import React, { Component, Fragment } from 'react';
import Switch from 'react-switch';
import styled from 'styled-components';
import moment from 'moment';
import axios from 'axios';
import { setArrayCheckedRemindedDays } from '../../util';
import { connect } from 'react-redux';
import packageDotJson from '../../../package.json';
import { detect } from 'detect-browser';
import Firebase from '../../util/Firebase';
import { requestFirebaseNotificationPermission } from './../../system/FirebaseMessage';
import platform from 'platform';
import _ from 'lodash';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import DefaultHeaderContainer from '../../common/containers/DefaultHeaderContainer';
import {
  GREYISH_BROWN,
  DARK_YELLOW,
  MARIGOLD,
  WHITE,
  LIGHT_GREY_2,
  GREY2,
  VERY_LIGHT_GREY,
  VERY_LIGHT_PINK_THREE,
  BLACK,
  BROWN_GREY_TWO,
  SEA_BLUE,
  DARK_GREY_1,
  PREFIX_HEADER,
  PREFIX_PROFILE,
  PREFIX_LESSON,
  VERY_LIGHT_PINK,
  VERY_LIGHT_PINK_TWO,
  LIGHT_GREY,
  SETTING_GREY_BACKGROUND,
  GREY,
  RED_COLOR,
  PRO_TXT,
  LING_TXT
} from '../../common/constants';
import './SettingScene.css';
import { FORM_GOOGLE_URL } from '../../shop/constants/ActionTypes';
import { ANONYMOUS } from '../constants';
import { RedeemSuccessModal } from '../sub-components/RedeemSuccessModal';
import { EditProfileModal } from '../sub-components/EditProfileModal';
import { DailyReminderModal } from '../../games/components/sub-components/DailyReminderModal/DailyReminderModal';
import { PurchaseLoginModal } from '../../shop/components/main-components/PurchaseLoginModal';

const couponAPI = 'usedCoupons';
type Props = {
  user: Object,
  history: Object,
  location: Object,
  setting_txt_contactUs: string,
  report_text_sendfeedback: string,
  setting_txt_aboutUs: string,
  my_daily_goal_txt: string,
  go_to_profile_txt: string,
  setting_txt_otherApp: string,
  setting_txt_settingHeader: string,
  btn_save: string,
  logout_txt: string,
  isEffectEnabled: boolean,
  setting_txt_effect: string,
  setting_txt_voice_speed: string,
  currentVoiceSpeedText: string,
  other_txt: string,
  setting_voice_speed_1_txt: string,
  setting_voice_speed_2_txt: string,
  setting_voice_speed_3_txt: string,
  setting_voice_speed_4_txt: string,
  setting_voice_speed_5_txt: string,
  saveChange: Function,
  signOut: Function,
  currentEffectEnabled: boolean,
  currentVoiceSpeed: number,
  userInfo: Object,
  updateUserProfile: Function,
  handleTimeChange: Function,
  setDailyGoal: Function,
  name: string,
  footer_txt_profile: string,
  nativeLangCode: string,
  targetLangCode: string,
  unitObject: Object,
  redeem_txt_desc: string,
  redeem_btn_redeem: string,
  redeem_txt_input: string,
  redeem_txt_invalid: string,
  modal_txt_redeem: string,
  modal_btn_redeem_go_home: string,
  profile_edit_profile_txt: string,
  functions: string,
  createAccount_txt_title: string,
  loginPurchase_txt: string,
  photoURL: string,
  newReminder: Object,
  setReminders: Function,
  setClientToken: Function,
  saveName: Function,
  saveProfilePic: Function,
  edit_profile_save_txt: string,
  setting_txt_reminder_title: string,
  modal_txt_notification_permission_title: string,
  setting_txt_day_select: string,
  setting_txt_time_select: string,
  loading_txt_cancel: string,
  edit_profile_photo_txt: string,
  edit_profile_edit_txt: string,
  login_field_name: string,
  setting_txt_version: string,
  isLoading: boolean
};
type State = {
  isEffect: boolean,
  dropdownOpen: boolean,
  soundSpeed: number,
  isChange: boolean,
  isRedeemSuccess: boolean,
  coupon: string,
  loading: boolean,
  isError: boolean,
  isLoginPurchase: boolean,
  isReminderOpen: boolean,
  isReminder: boolean,
  weekDays: Array<Object>,
  timeReminder: string,
  isSetReminder: boolean,
  remindedSelectDays: string,
  dayCheckCount: number,
  isEditScene: boolean,
  profileSelectId: number,
  newName: string,
  isNameChange: boolean,
  isEditing: boolean,
  editingId: string,
  selectedFile: Object
};

const SettingContainer = styled.div`
  margin: auto;
  margin-top: 96px;
  width: 784px !important;
  display: flex;
`;
const LeftSection = styled.div`
  margin-right: 20px;
`;
const RightSection = styled.div`
  margin-left: 28px;
  margin-top: 24px;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin: auto;
  transform: rotate(${props => (props.arrowDirection ? 90 : -90)}deg);
`;
const SoundSpeedIcon = styled.img`
  width: 18px;
  height: 18px;
  margin: auto;
  margin-right: 10px;
`;

const SettingTitle = styled.p`
  font-size: 24px;
  font-weight: bold;
  color: ${GREYISH_BROWN};
`;
const VersionText = styled.p`
  color: ${GREY};
  text-align: center;
  font-size: 15px;
`;
const EditSaveButton = styled.button`
  text-transform: capitalize;
  margin-top: 26px;
  margin-bottom: 25px;
  border-radius: 10px;
  box-shadow: inset 0 -4px 0 0 ${LIGHT_GREY};
  background: ${WHITE};
  border: ${GREY} 1px solid;
  font-size: 14px;
  width: 113px;
  height: 45px;
  padding: 0;
  color: ${DARK_GREY_1};
  bottom: 17px;
  right: 125px;
  &:disabled {
    background: ${LIGHT_GREY_2};
    box-shadow: inset 0 -4px 0 0 ${GREY2};
    border: ${GREY2} 1px solid;
  }
  &:hover:enabled {
    opacity: 0.4;
    box-shadow: none;
  }
  &:active:enabled {
    opacity: 1;
    background-color: ${GREY2};
    box-shadow: none;
  }
`;

const SettingSaveButton = styled.button`
  text-transform: uppercase;
  margin-top: 26px;
  margin-bottom: 25px;
  border-radius: 20px;
  box-shadow: inset 0 -4px 0 0 ${DARK_YELLOW};
  background: ${MARIGOLD};
  border: ${DARK_YELLOW} 1px solid;
  font-size: 14px;
  width: 157px;
  height: 55px;
  padding: 0;
  color: ${WHITE};
  bottom: 17px;
  right: 125px;
  &:disabled {
    background: ${LIGHT_GREY_2};
    box-shadow: inset 0 -4px 0 0 ${GREY2};
    border: ${GREY2} 1px solid;
  }
  &:hover:enabled {
    opacity: 0.4;
    box-shadow: none;
  }
  &:active:enabled {
    opacity: 1;
    background-color: ${DARK_YELLOW};
    box-shadow: none;
  }
`;
const SettingRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.soundSpeed {
    margin-top: 23px;
  }
`;
const SoundSetting = styled.p`
  font-size: 16px;
  color: ${VERY_LIGHT_GREY};
  margin-top: 11px !important;
  margin-bottom: 11px !important;
`;
const DropdownArrow = styled.div`
  position: relative;
`;
const SoundSettingToggle = styled.div``;
const SoundSpeedDrop = styled.div`
  margin-right: 3px;
  z-index: 3;
`;
const OptionItemRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const OptionArrow = styled.img`
  width: 15px;
  height: 15px;
  opacity: 0.6;
  transform: rotate(180deg);
`;
const Line = styled.div`
  width: 435px;
  height: 2px;
  background-color: ${VERY_LIGHT_PINK_THREE};
  margin-top: 30px;
  margin-bottom: 47px;
`;
const OtherText = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin-top: 17px !important;
  margin-bottom: 15px !important;
`;
const OptionItemContainer = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${VERY_LIGHT_PINK};
  cursor: pointer;
  &:hover {
    background: ${LIGHT_GREY};
    text-decoration: none;
  }
`;
const OptionItemImg = styled.img`
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background-color: ${BLACK};
  padding: 7px;
`;
const OptionItemText = styled.p`
  font-size: 16px;
  color: ${GREYISH_BROWN};
  margin-left: 19px;
  margin-top: 19px !important;
  margin-bottom: 20px !important;
`;
const ProfileBox = styled.div`
  border: 1px solid ${VERY_LIGHT_PINK_TWO};
  width: auto;
  margin-bottom: 10px;
  border-radius: 20px;
  padding-top: 20px;
  &.coupon {
    padding-top: 10px;
  }
`;
const UserInfoContainer = styled.div`
  margin-left: 8px;
  margin-top: 7px;
  display: ${props => (props.name === ANONYMOUS ? 'flex' : 'unset')};
  align-items: ${props => (props.name === ANONYMOUS ? 'center' : 'unset')};
`;
const ProfileContainer = styled.div`
  display: flex;
  padding-left: 38px;
  padding-right: 38px;
  margin-bottom: 24px;
  border-bottom: 1px ${VERY_LIGHT_PINK_TWO} solid;
`;

const ProfileImg = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
`;
const UserNameText = styled.p`
  font-size: 21px;
  font-weight: bold;
  color: ${GREYISH_BROWN};
  margin: 0 !important;
  line-height: 29px;
`;
const UserEmailText = styled.p`
  font-size: 16px;
  color: ${BROWN_GREY_TWO};
  margin: 0 !important;
`;
const GotoProfileText = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: ${SEA_BLUE};
  text-align: start;
  padding-left: 20px;
  cursor: pointer;
  margin-bottom: 10px !important;
`;
const LogOutText = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: ${DARK_GREY_1};
  text-align: start;
  padding-left: 20px;
  margin-top: 10px !important;
  cursor: pointer;
`;
const RedeemButton = styled.button`
  width: 106px;
  height: 48px;
  border-radius: 10px;
  box-shadow: inset 0 -4px 0 0 ${DARK_YELLOW};
  border: solid 1px ${MARIGOLD};
  background-color: ${MARIGOLD};
  color: ${WHITE};
  margin-left: 6px;
  &:hover:enabled {
    opacity: 0.4;
    box-shadow: none;
  }
  &:active:enabled {
    opacity: 1;
    background-color: ${DARK_YELLOW};
    box-shadow: none;
  }
`;
const RedeemErrorMsg = styled.p`
  font-size: 12px;
  font-weight: 300;
  color: ${RED_COLOR};
  margin-top: 9px !important;
  margin-bottom: 10px !important;
  margin-left: 13px !important;
`;
const RedeemContainer = styled.form`
  display: flex;
  margin-bottom: 15px;
  margin-left: 13px;
  margin-right: 13px;
`;
const CouponCode = styled.input`
  width: 170px;
  height: 45px;
  border-radius: 10px;
  border: solid 2px ${GREY2};
  padding-left: 12px;

  &:focus {
    outline: none;
  }
  &::placeholder {
    font-size: 14px;
    color: ${GREY};
  }
`;

const SettingText = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: ${DARK_GREY_1};
  text-align: start;
  padding-left: 20px;
  padding-bottom: 5px;
  padding-top: 5px;
  border-radius: 10px;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  cursor: pointer;
  span {
    background: ${SEA_BLUE};
    padding: 3px 13px;
    border-radius: 6px;
    color: ${WHITE};
    font-size: 14px;
  }
  &.redeemTitle {
    font-size: 18px;
    padding-left: 13px;
    margin-top: 0px !important;
    cursor: unset;
  }
  &.setting {
    background: ${SETTING_GREY_BACKGROUND};
  }
`;

const DayArray = styled.span`
  font-size: 15px;
`;
const styles = {
  dropdownBtn: {
    height: 40,
    width: 35,
    margin: 'auto',
    padding: 0,
    border: 'none',
    backgroundColor: WHITE,
    color: BLACK,
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: 'none',
    marginLeft: 4
  },
  dropdownMenuStyle: {
    width: 250,
    borderRadius: 10,
    padding: 0,
    boxShadow: '0 2px 20px 0 rgba(0, 0, 0, 0.15)',
    border: 'none',
    top: 37,
    left: -183,
    background: WHITE,
    zIndex: -1
  },
  dropdownItemStyle: {
    width: 182,
    paddingLeft: 52,
    paddingRight: 16,
    paddingTop: 16,
    paddingBottom: 14
  },
  dropdownItemActive: {
    width: 210,
    paddingLeft: 24,
    paddingRight: 16,
    paddingTop: 16,
    paddingBottom: 14,
    color: BLACK
  },
  dropdownBtnContainer: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    position: 'unset'
  }
};

const BackArrowIcon = `${PREFIX_HEADER}back-icon.svg`;
const CheckIcon = `${PREFIX_HEADER}check-1-px.svg`;
const EnvelopeIcon = `${PREFIX_HEADER}outline-envelope-25-px.svg`;
const InfoIcon = `${PREFIX_HEADER}outline-info-25-px2.svg`;
const PhoneIcon = `${PREFIX_HEADER}outline-phone-25-px2.svg`;
const WindowIcon = `${PREFIX_HEADER}outline-window-25-px2.svg`;
const redeemSuccessImg = `${PREFIX_HEADER}ling-thank-you-image-black@2x.png`;
const lingFemale = `${PREFIX_LESSON}avatar-circle-female-60-px.svg`;
const getProfileImage = (
  photoURL: ?string,
  defaultProfilePics: Array<Object>
) =>
  Number(photoURL) < 7
    ? (defaultProfilePics[Number(photoURL) - 1] &&
        defaultProfilePics[Number(photoURL) - 1].icon) ||
      lingFemale
    : photoURL
    ? photoURL
    : lingFemale;

class SettingScene extends Component<Props, State> {
  child: any;
  defaultProfilePics: Array<Object>;
  constructor(props: Props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      isEffect: true,
      dropdownOpen: false,
      soundSpeed: 1,
      coupon: '',
      isChange: false,
      isRedeemSuccess: false,
      loading: false,
      isError: false,
      isLoginPurchase: false,
      isEditScene: false,
      newName: '',
      isNameChange: false,
      isEditing: true,
      selectedFile: {},
      editingId: '',
      profileSelectId: 0,
      isReminderOpen: false,
      isReminder: false,
      remindedSelectDays: 'Everyday',
      isSetReminder: true,
      weekDays: [
        {
          id: 0,
          day: 'M',
          dayShort: 'Mon',
          isChecked: true
        },
        {
          id: 1,
          day: 'T',
          dayShort: 'Tue',
          isChecked: true
        },
        {
          id: 2,
          day: 'W',
          dayShort: 'Wed',
          isChecked: true
        },
        {
          id: 3,
          day: 'T',
          dayShort: 'Thu',
          isChecked: true
        },
        {
          id: 4,
          day: 'F',
          dayShort: 'Fri',
          isChecked: true
        },
        {
          id: 5,
          day: 'S',
          dayShort: 'Sat',
          isChecked: true
        },
        {
          id: 6,
          day: 'S',
          dayShort: 'Sun',
          isChecked: true
        }
      ],
      timeReminder: '09:30',
      dayCheckCount: 7
    };

    this.defaultProfilePics = [
      {
        id: 1,
        icon: `${PREFIX_PROFILE}avatar01.png`
      },
      {
        id: 2,
        icon: `${PREFIX_PROFILE}avatar02.png`
      },
      {
        id: 3,
        icon: `${PREFIX_PROFILE}avatar03.png`
      },
      {
        id: 4,
        icon: `${PREFIX_PROFILE}avatar04.png`
      },
      {
        id: 5,
        icon: `${PREFIX_PROFILE}avatar05.png`
      },
      {
        id: 6,
        icon: `${PREFIX_PROFILE}avatar06.png`
      }
    ];
  }
  componentDidMount() {
    const {
      currentEffectEnabled,
      currentVoiceSpeed,
      newReminder,
      name
    } = this.props;
    const soundSpeedList = [0.5, 0.75, 1, 1.25, 1.5];
    const isNotLing2SoundSpeed = !_.includes(soundSpeedList, currentVoiceSpeed);
    const { isSetReminder, weekDays } = newReminder;
    window.scrollTo(0, 0);
    this.getReminderData();
    setArrayCheckedRemindedDays(weekDays, this.stateChangeHandler);

    this.setState({
      newName: name,
      isEffect: currentEffectEnabled,
      soundSpeed: isNotLing2SoundSpeed ? 1 : currentVoiceSpeed,
      isReminder: isSetReminder
    });
  }

  onChange = (text: string) => {
    const { name } = this.props;
    if (name !== text) {
      this.setState({ newName: text, isNameChange: true });
    } else {
      this.setState({ newName: text, isNameChange: false });
    }
  };

  saveNewName = () => {
    const { user, saveName } = this.props;
    const { newName } = this.state;
    saveName(user, newName);
    this.setState({ isNameChange: false, editingId: '' });
  };
  selectPhotoTapped = event => {
    this.setState({
      selectedFile: event.target.files[0]
    });

    this.defaultProfilePics.push({
      id: this.defaultProfilePics.length + 1,
      // eslint-disable-next-line no-undef
      icon: URL.createObjectURL(event.target.files[0])
    });
  };
  fileUploadHandler = () => {};
  updateProfilePic = () => {
    const { user, saveProfilePic } = this.props;
    const { profileSelectId, selectedFile } = this.state;
    saveProfilePic(
      user,
      this.defaultProfilePics[profileSelectId - 1],
      selectedFile
    );
    this.setState({ profileSelectId: 0 });
    this.setEditId('');
  };
  toggleEditScene = () => {
    const { isEditScene } = this.state;
    this.setState({ isEditScene: !isEditScene, editingId: '' });
    this.resetSelect();
  };

  resetSelect = () => {
    this.setState({ profileSelectId: 0 });
  };
  getReminderData = () => {
    const { newReminder } = this.props;

    if (newReminder) {
      const { weekDays, timeReminder, isSetReminder } = newReminder;
      this.setState({
        weekDays: weekDays,
        timeReminder: timeReminder,
        isSetReminder: isSetReminder
      });
    }
  };
  couponChange = e => {
    this.setState({
      coupon: e.target.value
    });
  };
  submitCoupon = e => {
    const { functions, name, updateUserProfile } = this.props;
    e.preventDefault();
    if (name !== ANONYMOUS) {
      this.setState({
        isRedeemSuccess: true,
        loading: true
      });

      axios
        .post(`${functions}${couponAPI}`, {
          uid: this.props.user.uid,
          couponCode: this.state.coupon
        })
        .then(res => {
          if (res.status === 200) {
            if (res.data.success) {
              updateUserProfile({ subscription: res.data.subscription });
              this.setState({
                isRedeemSuccess: true,
                isError: false
              });
            } else {
              this.setState({
                isRedeemSuccess: false,
                isError: true
              });
            }
          }
        })
        // eslint-disable-next-line handle-callback-err
        .catch(err => {})
        .finally(() => {
          setTimeout(() => {
            this.setState({
              loading: false
            });
          }, 500);
        });
    } else {
      // this.child.toggleCreateAccount();
      this.toggleLoginPurchase();
    }
  };
  toggleLoginPurchase = () => {
    this.setState({
      isLoginPurchase: !this.state.isLoginPurchase
    });
  };
  setEditId = (editingId: string) => {
    const { name } = this.props;
    this.setState({
      editingId: editingId,
      newName: name
    });
  };
  toggleCreateAccount = () => {
    this.toggleLoginPurchase();
    this.child.toggleCreateAccount();
  };

  handleChange = (isEffect: boolean) => {
    this.setState({ isEffect, isChange: true });
  };

  handleReminderChange = (isReminder: boolean) => {
    if (
      //$FlowFixMe
      Notification.permission === 'denied' // eslint-disable-line
    ) {
      alert('Notification is disabled, please enable it and try again');
    } else {
      requestFirebaseNotificationPermission()
        .then(firebaseToken => {
          console.log('token', firebaseToken);
          this.props.setClientToken(firebaseToken);
          this.setState({ isReminder, isChange: true });
        })
        .catch(err => {
          return err;
        });
    }
  };
  toggleSoundSpeed = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  };
  triggerChildAlert = () => {
    this.child.current.showAlert();
  };

  toggleReminder = () => {
    this.getReminderData();
    this.setState({
      isReminderOpen: !this.state.isReminderOpen
    });
  };

  setArrayCheckedRemindedDays = weekDays => {
    let newWeekDay = ['Everyday', 'Someday'];
    const selectCount = _.countBy(weekDays, value => value.isChecked === true)
      .true;

    if (selectCount !== 7) {
      this.setState({
        remindedSelectDays: newWeekDay[1]
      });
    } else {
      this.setState({
        remindedSelectDays: newWeekDay[0]
      });
    }
  };

  stateChangeHandler = (name: string, value: any) => {
    this.setState({
      [name]: value
    });
  };

  onSubmit = () => {
    const linebreak = '%0D%0A';
    const { currentUser } = Firebase.auth();
    const { nativeLangCode, targetLangCode, user } = this.props;
    const date = moment();
    const subject = `[Ling Web] Report Tracking ${date.format('X')}`;
    const body = `
    Description:  ${linebreak}${linebreak}${linebreak}
    System info: ${linebreak}
      Screen Size : ${window.screen.width} x ${
      window.screen.height
    } ${linebreak}
      Browser : ${platform.name} ${linebreak}
      Versions: ${platform.version} ${linebreak}
      Product : ${platform.product} ${linebreak}
      Manufacturer : ${platform.manufacturer} ${linebreak}
      OS : ${platform.os} ${linebreak}
      nativeLangCode : ${nativeLangCode} ${linebreak}
      targetLangCode : ${targetLangCode} ${linebreak}
      User : ${
        currentUser.isAnonymous
          ? ANONYMOUS
          : `${linebreak}
        UID : ${user.uid} ${linebreak}
        name : ${user.name} ${linebreak}`
      }${linebreak}
`;
    window.location.href = `mailto:support@simyasolutions.com?subject=${subject}&body=${body}`;
  };
  goToHome() {
    const { history } = this.props;
    history.push('/');
  }
  toggle = () => {
    this.setState({
      isRedeemSuccess: !this.state.isRedeemSuccess
    });
  };
  onSelectProfile = (id: number) => {
    this.setState({ profileSelectId: id });
  };
  saveChange = () => {
    const { isEffect, soundSpeed, isChange, isReminder } = this.state;
    const { saveChange, updateUserProfile, newReminder } = this.props;
    this.setState({
      isChange: !isChange
    });
    saveChange(soundSpeed, isEffect, {
      ...newReminder,
      isSetReminder: isReminder
    });
    updateUserProfile({ voiceSpeed: soundSpeed });
  };

  changeHandler = (value: number) => {
    this.setState({ soundSpeed: value, isChange: true });
  };
  stateChangeHandler = (name: string, value: any) => {
    this.setState({
      [name]: value
    });
  };

  render() {
    const {
      setDailyGoal,
      history,
      location,
      setting_txt_contactUs,
      report_text_sendfeedback,
      setting_txt_aboutUs,
      setting_txt_otherApp,
      setting_txt_settingHeader,
      btn_save,
      logout_txt,
      signOut,
      setting_txt_effect,
      setting_txt_voice_speed,
      setting_voice_speed_1_txt,
      setting_voice_speed_2_txt,
      setting_voice_speed_3_txt,
      setting_voice_speed_4_txt,
      setting_voice_speed_5_txt,
      my_daily_goal_txt,
      other_txt,
      footer_txt_profile,
      userInfo,
      name,
      photoURL,
      redeem_txt_desc,
      redeem_btn_redeem,
      redeem_txt_input,
      redeem_txt_invalid,
      modal_txt_redeem,
      modal_btn_redeem_go_home,
      createAccount_txt_title,
      loginPurchase_txt,
      setReminders,
      setClientToken,
      nativeLangCode,
      newReminder,
      edit_profile_save_txt,
      modal_txt_notification_permission_title,
      setting_txt_reminder_title,
      setting_txt_day_select,
      setting_txt_time_select,
      profile_edit_profile_txt,
      isLoading,
      loading_txt_cancel,
      edit_profile_photo_txt,
      edit_profile_edit_txt,
      login_field_name,
      setting_txt_version
    } = this.props;
    const {
      dropdownOpen,
      isEffect,
      isReminder,
      soundSpeed,
      coupon,
      isError,
      remindedSelectDays,
      isEditScene,
      profileSelectId,
      isEditing,
      editingId,
      newName,
      isNameChange
    } = this.state;
    const {
      dropdownBtn,
      dropdownMenuStyle,
      dropdownItemStyle,
      dropdownItemActive,
      dropdownBtnContainer
    } = styles;
    const soundLabels = {
      '0.5': setting_voice_speed_1_txt,
      '0.75': setting_voice_speed_2_txt,
      '1': setting_voice_speed_3_txt,
      '1.25': setting_voice_speed_4_txt,
      '1.5': setting_voice_speed_5_txt
    };
    const soundSpeedList = [0.5, 0.75, 1, 1.25, 1.5];
    const currentSoundSpeedText = soundLabels[_.toString(soundSpeed)];
    return (
      <Fragment>
        <DefaultHeaderContainer
          isSrollable={false}
          history={history}
          location={location}
          onRef={ref => (this.child = ref)}
        />
        <SettingContainer>
          <LeftSection>
            <div>
              <SettingRowContainer>
                <SettingTitle>{setting_txt_settingHeader}</SettingTitle>
                <SettingSaveButton
                  disabled={!this.state.isChange}
                  onClick={this.saveChange}
                >
                  {btn_save}
                </SettingSaveButton>
              </SettingRowContainer>
              {detect() &&
                detect()
                  .name.toLowerCase()
                  .indexOf('safari') === -1 && (
                  <SettingRowContainer>
                    <SoundSetting>
                      {modal_txt_notification_permission_title}
                    </SoundSetting>
                    <SoundSettingToggle>
                      <Switch
                        onChange={this.handleReminderChange}
                        checked={isReminder}
                        offColor={LIGHT_GREY_2}
                        onColor={MARIGOLD}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        width={51}
                        height={30}
                      />
                    </SoundSettingToggle>
                  </SettingRowContainer>
                )}
              {isReminder && (
                <SettingRowContainer
                  id="SettingRowContainer"
                  onClick={() => this.toggleReminder()}
                >
                  <SoundSetting>
                    {_.map(remindedSelectDays, day => {
                      return <DayArray>{day}</DayArray>;
                    })}
                  </SoundSetting>

                  <SoundSettingToggle>
                    {newReminder.timeReminder + ' '}
                    <Icon src={BackArrowIcon} arrowDirection={dropdownOpen} />
                  </SoundSettingToggle>
                </SettingRowContainer>
              )}
              <SettingRowContainer>
                <SoundSetting>{setting_txt_effect}</SoundSetting>
                <SoundSettingToggle>
                  <Switch
                    onChange={this.handleChange}
                    checked={isEffect}
                    offColor={LIGHT_GREY_2}
                    onColor={MARIGOLD}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    width={51}
                    height={30}
                  />
                </SoundSettingToggle>
              </SettingRowContainer>
              <SettingRowContainer className="soundSpeed">
                <SoundSetting>{setting_txt_voice_speed}</SoundSetting>
                <SoundSpeedDrop>
                  <Dropdown
                    isOpen={dropdownOpen}
                    toggle={this.toggleSoundSpeed}
                    style={dropdownBtnContainer}
                  >
                    {currentSoundSpeedText}
                    <DropdownArrow>
                      <DropdownToggle style={dropdownBtn}>
                        {dropdownOpen ? (
                          <Icon
                            src={BackArrowIcon}
                            arrowDirection={dropdownOpen}
                          />
                        ) : (
                          <Icon
                            src={BackArrowIcon}
                            arrowDirection={dropdownOpen}
                          />
                        )}
                      </DropdownToggle>
                      <DropdownMenu style={dropdownMenuStyle}>
                        {_.map(soundSpeedList, (soundValue, index) => {
                          return (
                            <DropdownItem
                              key={index}
                              style={
                                _.isEqual(soundSpeed, soundValue)
                                  ? dropdownItemActive
                                  : dropdownItemStyle
                              }
                              className="dropdownItemClass"
                              onClick={() => {
                                this.changeHandler(soundValue);
                              }}
                            >
                              {_.isEqual(soundSpeed, soundValue) ? (
                                <SoundSpeedIcon src={CheckIcon} />
                              ) : null}
                              {soundLabels[_.toString(soundValue)]}
                            </DropdownItem>
                          );
                        })}
                      </DropdownMenu>
                    </DropdownArrow>
                  </Dropdown>
                </SoundSpeedDrop>
              </SettingRowContainer>
            </div>
            <Line />
            <div>
              <OtherText>{other_txt}</OtherText>
              <div>
                <OptionItemContainer
                  href="https://simyasolutions.com/"
                  target="_blank"
                >
                  <OptionItemRight>
                    <OptionItemImg src={InfoIcon} />
                    <OptionItemText>{setting_txt_aboutUs}</OptionItemText>
                  </OptionItemRight>
                  <OptionArrow src={BackArrowIcon} />
                </OptionItemContainer>
                <OptionItemContainer
                  href="https://simyasolutions.com/apps-overview/"
                  target="_blank"
                >
                  <OptionItemRight>
                    <OptionItemImg src={WindowIcon} />
                    <OptionItemText>{setting_txt_otherApp}</OptionItemText>
                  </OptionItemRight>
                  <OptionArrow src={BackArrowIcon} />
                </OptionItemContainer>
                <OptionItemContainer href={FORM_GOOGLE_URL} target="_blank">
                  <OptionItemRight>
                    <OptionItemImg src={PhoneIcon} />
                    <OptionItemText>{report_text_sendfeedback}</OptionItemText>
                  </OptionItemRight>
                  <OptionArrow src={BackArrowIcon} />
                </OptionItemContainer>
                <OptionItemContainer onClick={this.onSubmit}>
                  <OptionItemRight>
                    <OptionItemImg src={EnvelopeIcon} />
                    <OptionItemText>{setting_txt_contactUs}</OptionItemText>
                  </OptionItemRight>
                  <OptionArrow src={BackArrowIcon} />
                </OptionItemContainer>
              </div>
              <VersionText>
                {setting_txt_version} {packageDotJson.version}
              </VersionText>
            </div>
          </LeftSection>
          <RightSection>
            <ProfileBox>
              <ProfileContainer>
                <ProfileImg
                  src={getProfileImage(photoURL, this.defaultProfilePics)}
                />
                <UserInfoContainer name={name}>
                  <UserNameText>{name}</UserNameText>
                  {name !== ANONYMOUS && (
                    <Fragment>
                      <UserEmailText>{userInfo.email}</UserEmailText>
                      <EditSaveButton
                        id="EditSaveButton"
                        onClick={() => this.toggleEditScene()}
                      >
                        {profile_edit_profile_txt}
                      </EditSaveButton>
                    </Fragment>
                  )}
                </UserInfoContainer>
              </ProfileContainer>
              <GotoProfileText
                id="GotoProfileText"
                onClick={() => {
                  history.push('/profile');
                }}
              >
                {footer_txt_profile}
              </GotoProfileText>
              <SettingText className="setting">
                {setting_txt_settingHeader}
              </SettingText>
              <SettingText
                id="SettingText"
                onClick={() => {
                  history.push('/subscription', { source: 'settings' });
                }}
              >
                {LING_TXT} <span>{PRO_TXT}</span>
              </SettingText>
              {name !== ANONYMOUS && (
                <LogOutText
                  id="LogOutText"
                  onClick={() => {
                    history.push('/');
                    signOut();
                  }}
                >
                  {logout_txt}
                </LogOutText>
              )}
            </ProfileBox>
            <ProfileBox className="coupon">
              <SettingText className="redeemTitle">
                {redeem_txt_desc}
              </SettingText>
              <RedeemContainer>
                <CouponCode
                  placeholder={redeem_txt_input}
                  value={coupon}
                  onChange={this.couponChange.bind(this)}
                />
                <RedeemButton href={'#'} onClick={this.submitCoupon.bind(this)}>
                  {redeem_btn_redeem}
                </RedeemButton>
              </RedeemContainer>
              {isError ? (
                <RedeemErrorMsg>{redeem_txt_invalid}</RedeemErrorMsg>
              ) : (
                ''
              )}
            </ProfileBox>
          </RightSection>
        </SettingContainer>
        <DailyReminderModal
          updateUserProfile={() => {}}
          setDailyGoal={setDailyGoal}
          my_daily_goal_txt={my_daily_goal_txt}
          edit_profile_save_txt={edit_profile_save_txt}
          nativeLangCode={nativeLangCode}
          isHomePage={false}
          isOpen={this.state.isReminderOpen}
          toggle={this.toggleReminder}
          closeReminder={this.toggleReminder}
          setReminders={setReminders}
          setClientToken={setClientToken}
          stateChangeHandler={this.stateChangeHandler}
          reminderData={newReminder}
          setArrayCheckedRemindedDays={this.setArrayCheckedRemindedDays}
          setting_txt_reminder_title={setting_txt_reminder_title}
          setting_txt_day_select={setting_txt_day_select}
          setting_txt_time_select={setting_txt_time_select}
        />
        <RedeemSuccessModal
          toggle={this.toggle}
          isLoading={this.state.loading}
          goToHome={() => {
            this.goToHome();
          }}
          isOpen={this.state.isRedeemSuccess}
          redeemSuccessImg={redeemSuccessImg}
          modal_txt_redeem={modal_txt_redeem}
          modal_btn_redeem_go_home={modal_btn_redeem_go_home}
        />
        <EditProfileModal
          edit_profile_save_txt={edit_profile_save_txt}
          loading_txt_cancel={loading_txt_cancel}
          edit_profile_photo_txt={edit_profile_photo_txt}
          edit_profile_edit_txt={edit_profile_edit_txt}
          login_field_name={login_field_name}
          defaultProfilePics={this.defaultProfilePics}
          isLoading={isLoading}
          selectPhotoTapped={this.selectPhotoTapped}
          updateProfilePic={this.updateProfilePic}
          saveNewName={this.saveNewName}
          isNameChange={isNameChange}
          onChange={this.onChange}
          newName={newName}
          setEditId={this.setEditId}
          isEditing={isEditing}
          editingId={editingId}
          profileSelectId={profileSelectId}
          onSelectProfile={this.onSelectProfile}
          toggleEdit={this.toggleEditScene}
          goToHome={() => {
            this.goToHome();
          }}
          isOpen={isEditScene}
          profileImg={getProfileImage(photoURL, this.defaultProfilePics)}
          modal_header_txt={profile_edit_profile_txt}
          modal_txt_redeem={modal_txt_redeem}
          modal_btn_redeem_go_home={modal_btn_redeem_go_home}
        />

        <PurchaseLoginModal
          createAccount_txt_title={createAccount_txt_title}
          loginPurchase_txt={loginPurchase_txt}
          isLoginPurchase={this.state.isLoginPurchase}
          manageSceneStateChangeHandler={this.stateChangeHandler}
          toggleCreateAccount={this.toggleCreateAccount}
        />
      </Fragment>
    );
  }
}

export const mapStateToProps = (state: Object) => {
  const { data, game, storage } = state;
  const { nativeLangCode, targetLangCode } = data;
  const { user } = storage;
  const unitObject = game.key.split(',');
  return {
    nativeLangCode,
    targetLangCode,
    unitObject,
    user: user
  };
};

export default connect(mapStateToProps)(SettingScene);
