/* @flow */
import { firebase } from '../system/Firebase';
import ReactGA from 'react-ga';
import BugTracker from './BugTracker';
import { getAppLanguage } from './AssetHelper';
import smartlookClient from 'smartlook-client';
import TagManager from 'react-gtm-module';
const SIMYA = 'simya';
const EXPOLIGUA = 'expolingua';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_TAGMANAGER_ID
};
export const initializeReactGA = () => {
  TagManager.initialize(tagManagerArgs);
  ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);
  ReactGA.ga('send', 'pageview');
};
export const initializeSmartLook = () => {
  try {
    if (process.env.NODE_ENV === 'production') {
      smartlookClient.init(process.env.REACT_APP_SMART_LOOK_KEY);
    }
  } catch (err) {
    BugTracker.notify(err);
  }
};
function Analytic() {
  this.key = {
    A1: 'complete_free_units',
    A2: 'purchase_confirm',
    A3: 'purchase_rate_app',
    A4: 'purchase_option',
    A5: 'purchase_shop',
    A6: 'purchase_screen',
    A7: 'login_user',
    A8: 'change_target_language',
    A9: 'skip_login',
    A10: 'skip_login_test',
    A11: 'shop_promotion',
    A12: 'purchase_shop_monthly',
    A13: 'purchase_shop_yearly',
    A14: 'purchase_shop_lifetime',
    G1: 'game_result',
    L1: 'lesson_start',
    L2: 'lesson_end',
    L3: 'lesson_complete',
    L4: 'lesson_retry',
    L5: 'lesson_restart',
    L6: 'exam_fail',
    U1: 'unit_download',
    U2: 'unit_start',
    U3: 'unit_completed',
    R1: 'rate_user',
    F1: 'hint_usage',
    F2: 'hint_zero',
    F3: 'transcription_off',
    D1: 'cross_promotion',
    V1: 'validate_purchases',
    D2: 'start_download',
    D3: 'finish_download'
  };
}

const firebaseAnalytics = firebase.analytics();

Analytic.prototype.prepareUser = () => {
  const { uid } = firebase.auth().currentUser;
  firebaseAnalytics.setUserId(uid);
  ReactGA.ga('set', 'userId', uid);
  ReactGA.ga('send', 'pageview');
  console.log('Logging user id to analytics', uid);
};

Analytic.prototype.updateExpolinguaUser = (isExpolingua: boolean) => {
  const userTypeKey = 'vendor';
  const type = isExpolingua ? EXPOLIGUA : SIMYA;
  const userProperties = {
    [userTypeKey]: type
  };

  console.log('Logging user properties', userProperties);
  firebaseAnalytics.setUserProperties(userProperties);
  if (process.env.NODE_ENV === 'production') {
    if (!smartlookClient.initialized()) {
      initializeSmartLook();
    }
    smartlookClient.properties(userProperties);
  }
  ReactGA.set(userProperties);
  ReactGA.ga('send', 'pageview');
};

Analytic.prototype.prepareUserIdentify = (email: string, name: string) => {
  const { uid } = firebase.auth().currentUser;
  if (process.env.NODE_ENV === 'production') {
    if (!smartlookClient.initialized()) {
      initializeSmartLook();
    }
    smartlookClient.identify(uid, {
      name: name,
      email: email
    });
  }
  console.log('Logging user id to analytics', uid);
};
Analytic.prototype.prepareUserUid = () => {
  const { uid } = firebase.auth().currentUser;
  if (process.env.NODE_ENV === 'production') {
    if (!smartlookClient.initialized()) {
      initializeSmartLook();
    }
    smartlookClient.identify(uid);
  }
  console.log('Logging user id to analytics', uid);
};

Analytic.prototype.updateTargetLanguageInUser = (targetLanguage: string) => {
  const targetLanguageKey = 'target_language';
  const appLanguageKey = 'app_language';

  const appLanguage = getAppLanguage();
  const userProperties = {
    [targetLanguageKey]: targetLanguage,
    [appLanguageKey]: appLanguage
  };

  console.log('Logging user properties', userProperties);
  firebaseAnalytics.setUserProperties(userProperties);
  if (process.env.NODE_ENV === 'production') {
    if (!smartlookClient.initialized()) {
      initializeSmartLook();
    }
    smartlookClient.properties(userProperties);
  }
  ReactGA.set(userProperties);
  ReactGA.ga('send', 'pageview');
};

Analytic.prototype.log = (key: string, data?: Object) => {
  const parameter = data || {};
  firebaseAnalytics.logEvent(key, parameter);
  if (process.env.NODE_ENV === 'production') {
    if (!smartlookClient.initialized()) {
      initializeSmartLook();
    }
    smartlookClient.track(key, parameter);
  }

  BugTracker.breadcrumb(key, parameter);
};

Analytic.prototype.logGA = (key: string, data?: Object) => {
  const parameter = data || {};
  ReactGA.event(parameter);
  ReactGA.ga('send', 'pageview');
  BugTracker.breadcrumb(key, parameter);
};

const instance = new Analytic();
export default instance;
