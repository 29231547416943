import React from 'react';
import { Testimonials } from '../main-components/Testimonials';
import { useSelector } from 'react-redux';
import {
  selectTestimonialName,
  selectTestimonials
} from '../../constants/shopSelectors';

const TestimonialList = ({ animating }) => {
  const state = useSelector(state => state);
  const testimonials = selectTestimonials(state);
  const testimonialNames = selectTestimonialName(state);
  const shop_txt_testimonial_title =
    state.data.labels.byId['shop_txt_testimonial_title']?.content ?? '';
  return (
    <Testimonials
      animating={animating}
      trial_txt_testimonial_1={testimonials['trial_txt_testimonial_1']}
      trial_txt_testimonial_2={testimonials['trial_txt_testimonial_2']}
      trial_txt_testimonial_3={testimonials['trial_txt_testimonial_3']}
      trial_txt_testimonial_4={testimonials['trial_txt_testimonial_4']}
      trial_txt_testimonial_5={testimonials['trial_txt_testimonial_5']}
      trial_txt_testimonial_name_1={
        testimonialNames['trial_txt_testimonial_name_1']
      }
      trial_txt_testimonial_name_2={
        testimonialNames['trial_txt_testimonial_name_2']
      }
      trial_txt_testimonial_name_3={
        testimonialNames['trial_txt_testimonial_name_3']
      }
      trial_txt_testimonial_name_4={
        testimonialNames['trial_txt_testimonial_name_4']
      }
      trial_txt_testimonial_name_5={
        testimonialNames['trial_txt_testimonial_name_5']
      }
      shop_txt_testimonial_title={shop_txt_testimonial_title}
    />
  );
};

export default TestimonialList;
