/* eslint-disable max-lines */
/* @flow */
import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import Analytic from '../../util/Analytic';
import './HeaderMenu.css';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import styled from 'styled-components';
import {
  PREFIX_HEADER,
  PREFIX_PROFILE,
  WHITE,
  GRAY_BLUE,
  DARK_BLUE,
  DARK_BLUE_2,
  NOTIFICATION_COLOR,
  SHOW_TUTORIAL,
  IS_BANANAS,
  TRUE
} from '../../common/constants';
import { Chat, SchoolOutlined, ShoppingCartOutlined } from '@material-ui/icons';
import { Box } from '@mui/material';

import { ScoreModal } from './ScoreModal.js';

import { QuitModal, SignUpModal } from '../../games/components/sub-components';
import ReportModal from '../../games/components/sub-components/ReportModal/ReportModal';
import { isValidEmail } from '../../common/actions/AuthActions';
import MaterialButton from './MaterialButton';

const MenuBar = styled.div`
  min-height: 70px;
  max-height: 70px;
  align-self: right;
  padding-right: 13px;
  padding-left: 13px;
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-weight: bold;
`;

const Icon = styled.img`
  width: 22px;
  height: 12px;
`;

const FlagContainer = styled.div`
  width: 35px;
  height: 35px;
  border: solid 2px white;
  margin-right: 17px;
  border-radius: 35px;
  cursor: pointer;
`;

const CircleIcon = styled.img`
  width: 35px;
  height: 35px;
  border-radius: inherit;
`;
const ScoreContainer = styled.div`
  position: relative;
`;
const SquareIcon = styled.img`
  width: 35px;
  height: 35px;
  margin-right: 7px;
  cursor: pointer;
`;

const Score = styled.div`
  height: 35px;
  padding-top: 20px;
  margin-right: 25px;
  font-size: 14px;
`;

const Divider = styled.div`
  height: 0;
  overflow: hidden;
  border-top: 1px solid ${GRAY_BLUE};
`;

const CenterButtons = styled.div`
  display: flex;
`;

const CenterHeaderButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const NotificationContainer = styled.div`
  width: 25px;
  height: 25px;
  background: ${NOTIFICATION_COLOR};
  border-radius: 50%;
  position: absolute;
  bottom: 6px;
  right: 10px;
`;

const NotificationText = styled.p`
  position: absolute;
  top: 0;
  left: 50%;
  margin: 0 !important;
  transform: translateX(-50%);
  color: ${WHITE};
  font-size: 18px;
`;

const GiftIconContainer = styled.button`
  height: 55px;
  border-radius: 20px;
  background-color: ${DARK_BLUE};
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  box-shadow: inset 0 -4px 0 0 ${DARK_BLUE_2};
  border: solid 1px ${DARK_BLUE_2};
  justify-content: center;
  align-items: center;
  display: flex;
  padding-left: 17px;
  padding-right: 21px;
  margin-left: 18px;
  position: relative;
  &:hover {
    box-shadow: none;
    border-color: ${DARK_BLUE};
  }
  &:active {
    color: ${WHITE};
    background: ${DARK_BLUE_2};
    box-shadow: none;
    border-color: ${DARK_BLUE_2};
  }
`;

const styles = {
  dropdownBtn: {
    width: 28,
    height: 41,
    padding: 0,
    borderRadius: 8,
    border: 'none',
    backgroundColor: WHITE,
    marginRight: 31,
    marginTop: 6,
    boxShadow: 'none',
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0
  },
  dropdownBtnActive: {
    width: 28,
    height: 41,
    padding: 0,
    borderRadius: 8,
    border: 'none',
    backgroundColor: 'transparent',
    marginRight: 31,
    marginTop: 6,
    boxShadow: 'none',
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0
  },
  dropdownMenuStyle: {
    top: 42,
    left: -170,
    width: 218,
    borderRadius: 10,
    padding: 0,
    boxShadow: '0 2px 20px 0 rgba(0, 0, 0, 0.15)',
    border: 'none',
    zIndex: -1
  },
  dropdownItemStyle: {
    width: 186,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 14,
    paddingBottom: 14,
    fontWeight: 'bold'
  }
};

type Props = {
  location: Object,
  history: Object,
  signOut: Function,
  toggleCheckout: Function,
  signUpWithEmail: Function,
  pauseDialog: Function,
  email_error_alreadyUsed: string,
  email_error_userNotFound: string,
  email_error_wrongPassword: string,
  isScrolled: boolean,
  bananas: number,
  coins: number,
  name: string,
  flagUri: string,
  photoURL: string,
  createAccount_txt_title: string,
  notification_text_confirm: string,
  notification_text_cancel: string,
  login_msg_loseProgressDialog: string,
  logout_txt: string,
  email_error_matchPasswords: string,
  email_error_fieldsMissing: string,
  email_error_longerPassword: string,
  email_error_wrongFormat: string,
  login_field_name: string,
  login_field_firstName: string,
  login_msg_reenterPassword: string,
  login_field_email: string,
  login_field_password: string,
  email_txt_loginNow: string,
  reset_password_txt_description: string,
  user: Object,
  btn_continue: Function,
  sign_up_txt_title: string,
  login_txt_title: string,
  sign_in_opt_msg: string,
  email_txt_loginNow_link: string,
  already_register_txt: string,
  login_txt_forget_password_title: string,
  login_txt_forget_password_web: string,
  dont_have_account_yet_txt: string,
  at_least_6_password_txt: string,
  setting_txt_settingHeader: string,
  modal_error_faq: string,
  profile_txt_headerProfile: string,
  targetLangCode: string,
  nativeLangCode: string,
  signInWithEmailAndPassword: Function,
  sendPasswordResetEmail: Function,
  signInWithFacebook: Function,
  signInWithGoogle: Function,
  signInWithApple: Function,
  signInAnonymously: Function,
  toggleLoginModal: Function,
  footer_txt_chatbot: string,
  footer_txt_learn: string,
  footer_txt_shop: string,
  onRef: Function,
  isProUser: boolean,
  isLoginModalOpen: boolean,
  faq: Array<Object>,
  isDev: boolean,
  match: Object,
  isShowTutorial: boolean,
  showGift: boolean,
  promoCount: number,
  isBusinessSignUp: boolean,
  banana_score_modal_txt: string,
  star_score_modal_txt: string,
  setIsBusinessSignUp: Function,
  setSignUpBusinessSuccess: Function,
  fetchUnitData: Function,
  modal_error_other: string,
  modal_error_answer_should_be_accepted: string,
  modal_error_answer_duplicate: string,
  modal_error_image_missing: string,
  modal_error_audio_not_match: string,
  modal_error_audio_missing: string,
  modal_error_audio_not_clear: string,
  modal_error_report_txt: string,
  modal_error_report: string,
  modal_error_faq: string,
  txt_email_field_placeholder: string
};

type State = {
  user: Object,
  dropdownOpen: boolean,
  isLogOut: boolean,
  isLoading: boolean,
  modal: boolean,
  isResetPassword: boolean,
  isSignUp: boolean,
  successMsg: string,
  errorMsgFirebase: string,
  isEmailSent: boolean,
  isReportError: boolean,
  itemInfo: Object,
  productName: string,
  currency: string,
  price: number,
  pagePath: string,
  unitId: number,
  isScoreModalOpen: boolean,
  isBananaScoreModalOpen: boolean,
  isNewsletterEnable: boolean
};

const lingProfile = `${PREFIX_HEADER}Male.png`;
const giftIcon = `${PREFIX_HEADER}fill-gift-25-px.png`;

const getProfileImage = (
  photoURL: ?string,
  defaultProfilePics: Array<Object>
) => {
  return Number(photoURL) < 7 && photoURL
    ? defaultProfilePics[Number(photoURL) - 1].icon
    : photoURL
    ? photoURL
    : lingProfile;
};

class HeaderMenu extends Component<Props, State> {
  unitIdBussiness: number = 0;
  constructor(props: Props) {
    super(props);
    this.fbLogin = this.fbLogin.bind(this);

    this.googleLogin = this.googleLogin.bind(this);
    this.appleLogin = this.appleLogin.bind(this);
    this.login = this.login.bind(this);
    this.signUpWithEmail = this.signUpWithEmail.bind(this);
    this.toggle1 = this.toggle1.bind(this);
    this.toggleResetPassword = this.toggleResetPassword.bind(this);
    this.toggleSignUp = this.toggleSignUp.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.logOut = this.logOut.bind(this);
    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
      isLogOut: false,
      isReportError: false,
      isLoading: false,
      isScoreModalOpen: false,
      isBananaScoreModalOpen: false,
      modal: false,
      isResetPassword: false,
      isSignUp: true,
      successMsg: '',
      errorMsgFirebase: '',
      isEmailSent: false,
      unitId: 0,
      user: {
        name: '',
        email: '',
        password: '',
        confirmPassword: ''
      },
      itemInfo: {},
      productName: '',
      price: 0,
      currency: '',
      pagePath: '',
      isNewsletterEnable: true
    };
  }
  closeModal = (e: any) => {
    const CHECK_CLICK_OUTSIDE_MODAL =
      // $FlowFixMe
      !document.getElementById('score-modal-id').contains(e.target) &&
      // $FlowFixMe
      !document.getElementById('score-modal-id1').contains(e.target);
    if (CHECK_CLICK_OUTSIDE_MODAL) {
      this.setState({
        isScoreModalOpen: false
      });
    }
  };
  componentDidMount() {
    const {
      match: { path },
      onRef,
      location
    } = this.props;
    const { isScoreModalOpen, modal } = this.state;
    this.setState({
      pagePath: path
    });
    window.addEventListener('click', e => {
      isScoreModalOpen && this.closeModal(e);
    });
    try {
      onRef(this);
    } catch (error) {}
    if (location) {
      const { search } = location;
      if (search) {
        const queryIsTriggerModal = search.match(/login=([^&]*)/);
        if (queryIsTriggerModal) {
          queryIsTriggerModal[1] &&
            !modal &&
            this.setState({ isSignUp: false });
        }
      }
    }
  }
  openLogin = queryIsTriggerModal => {
    queryIsTriggerModal[1] &&
      !this.state.modal &&
      this.setState({ modal: true, isSignUp: false });
  };
  componentWillUnmount() {
    try {
      this.props.onRef(undefined);
    } catch (error) {}
  }

  toggleCreateAccountLanguagePicker() {
    const { history, location } = this.props;
    const { search } = location;
    if (search) {
      const queryUtmSource = search.match(/utm_source=([^&]*)/);
      if (queryUtmSource) {
        const utm_source = queryUtmSource[1];
        if (utm_source === '2021expolingua') {
          // eslint-disable-next-line no-undef
          localStorage.setItem('isExpolingua', 'true');
          navigator.userAgent.toLowerCase().indexOf('mobile') > -1 &&
            history.push('/claim');
          this.toggle();
        }
      }
    }
  }

  toggleCreateAccount(itemInfo, productName, price, currency) {
    this.setState({
      itemInfo: itemInfo,
      productName: productName,
      price: price,
      currency: currency
    });
    this.toggle();
  }
  toggleCreateAccountBusiness(unitId) {
    this.unitIdBussiness = unitId;
    this.toggle();
  }

  goToLink(link, source = null) {
    if (source) {
      this.props.history.push(link, { source: source });
    } else {
      this.props.history.push(link);
    }
  }

  logOut = async () => {
    this.setState({ isLoading: true, errorMsgFirebase: '' });
    const signMsg = await this.props.signOut();

    if (signMsg[0]) {
      const signAnonymousMsg = await this.props.signInAnonymously();
      if (signAnonymousMsg[0]) {
        this.setState({
          isLoading: false
        });
        this.toggleLogOut();
      }
    } else {
      this.setState({ errorMsgFirebase: signMsg[1].message });
    }
  };

  signUpWithEmail = async () => {
    const {
      isBusinessSignUp,
      setSignUpBusinessSuccess,
      fetchUnitData,
      targetLangCode,
      nativeLangCode,
      history,
      signUpWithEmail
    } = this.props;
    const { name, email, password } = this.state.user;

    this.setState({ isLoading: true, errorMsgFirebase: '' });
    const signMsg = await signUpWithEmail(
      name,
      email,
      password,
      this.state.isNewsletterEnable
    );
    if (signMsg[0]) {
      if (isBusinessSignUp) {
        setSignUpBusinessSuccess();
      }
      this.toggle();
      if (this.unitIdBussiness === 42) {
        fetchUnitData(targetLangCode, nativeLangCode, 42, () => {
          history.push('/unit/42');
        });
      } else {
        if (!_.isEmpty(this.state.itemInfo)) {
          this.redirectToStripe();
        }
      }
    } else {
      this.setState({
        errorMsgFirebase: this.getEmailLoginError(signMsg[1]).message
      });
      this.setState({ isLoading: false });
    }
  };
  redirectToStripe = () => {
    this.props.toggleCheckout();
  };
  getEmailLoginError = ({ code, message }) => {
    const {
      email_error_alreadyUsed,
      email_error_userNotFound,
      email_error_wrongPassword
    } = this.props;
    switch (code) {
      case 'auth/user-not-found':
        return {
          message: email_error_userNotFound
        };
      case 'auth/wrong-password':
        return {
          message: email_error_wrongPassword
        };
      case 'auth/email-already-in-use':
        return {
          message: email_error_alreadyUsed
        };
      default:
        return {
          message
        };
    }
  };
  login = async () => {
    const { user } = this.state;
    const { email, password } = user;
    this.setState({ isLoading: true, errorMsgFirebase: '' });
    const signMsg = await this.props.signInWithEmailAndPassword(
      email,
      password
    );
    if (signMsg[0]) {
      this.toggle();
      if (this.unitIdBussiness === 42) {
        this.props.history.push('/unit/42');
      } else {
        if (!_.isEmpty(this.state.itemInfo)) {
          this.redirectToStripe();
        }
      }
    } else {
      this.setState({
        errorMsgFirebase: this.getEmailLoginError(signMsg[1]).message
      });
      this.setState({ isLoading: false });
    }
  };

  toggle1 = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
      isLoading: false
    });
  };

  toggleLogOut = () => {
    this.setState({
      isLogOut: !this.state.isLogOut
    });
  };

  toggleErrorReport = () => {
    this.setState({
      isReportError: !this.state.isReportError
    });
  };

  resetPassword = async () => {
    const { user } = this.state;
    const { email } = user;
    const { sendPasswordResetEmail } = this.props;
    this.setState({
      isLoading: true,
      errorMsgFirebase: ''
    });
    const signMsg = await sendPasswordResetEmail(email);

    if (true) {
      this.setState({ isEmailSent: true });
    } else {
      this.setState({ errorMsgFirebase: signMsg[1].message });
    }
    this.setState({ isLoading: false });
  };

  fbLogin = async () => {
    const {
      isBusinessSignUp,
      setSignUpBusinessSuccess,
      history,
      fetchUnitData,
      targetLangCode,
      nativeLangCode
    } = this.props;
    if (await this.props.signInWithFacebook()) {
      if (isBusinessSignUp) {
        setSignUpBusinessSuccess();
      }
      this.toggle();
      if (this.unitIdBussiness === 42) {
        fetchUnitData(targetLangCode, nativeLangCode, 42, () => {
          history.push('/unit/42');
        });
      } else {
        if (!_.isEmpty(this.state.itemInfo)) {
          this.redirectToStripe();
        }
      }
    }
  };

  googleLogin = async () => {
    const {
      signInWithGoogle,
      fetchUnitData,
      targetLangCode,
      nativeLangCode
    } = this.props;
    if (await signInWithGoogle()) {
      this.toggle();
      if (this.unitIdBussiness === 42) {
        fetchUnitData(targetLangCode, nativeLangCode, 42, () => {
          this.props.history.push('/unit/42');
        });
      } else {
        if (!_.isEmpty(this.state.itemInfo)) {
          this.redirectToStripe();
        }
      }
    }
  };

  appleLogin = async () => {
    const {
      signInWithApple,
      fetchUnitData,
      targetLangCode,
      nativeLangCode
    } = this.props;
    if (await signInWithApple()) {
      this.toggle();
      if (this.unitIdBussiness === 42) {
        fetchUnitData(targetLangCode, nativeLangCode, 42, () => {
          this.props.history.push('/unit/42');
        });
      } else {
        if (!_.isEmpty(this.state.itemInfo)) {
          this.redirectToStripe();
        }
      }
    }
  };

  validate = values => {
    let errors = {};

    if (!values.email) {
      errors.email = 'Required';
    } else if (!isValidEmail) {
      errors.email = 'Invalid Email Address';
    }
    if (!values.password) {
      errors.password = 'Required';
    } else if (values.password.length < 6) {
      errors.password = 'Pessword too short';
    }
  };
  stateChangeHandler = (name: string, value: any) => {
    this.setState({
      [name]: value
    });
  };
  resetErrorMsg = () => {
    this.setState({ errorMsgFirebase: '', successMsg: '' });
  };

  toggle = () => {
    const {
      isLoginModalOpen,
      toggleLoginModal,
      isBusinessSignUp,
      setIsBusinessSignUp,
      pauseDialog
    } = this.props;
    if (isLoginModalOpen) {
      toggleLoginModal();
    } else {
      pauseDialog();
      this.setState(prevState => ({
        modal: !prevState.modal,
        isLoading: false,
        errorMsgFirebase: '',
        isResetPassword: false,
        isSignUp: true,
        unitId: 0
      }));
    }
    if (isBusinessSignUp) {
      setIsBusinessSignUp();
    }
    this.resetUser();
  };

  toggleLogin = () => {
    this.toggle();
    this.toggleSignUp();
  };

  resetUser = () => {
    this.setState({
      user: {
        name: '',
        email: '',
        password: '',
        confirmPassword: ''
      }
    });
  };

  toggleResetPassword = () => {
    this.setState(prevState => ({
      isResetPassword: !prevState.isResetPassword,
      errorMsgFirebase: ''
    }));
  };

  toggleSignUp = () => {
    this.setState(prevState => ({
      isSignUp: !prevState.isSignUp,
      errorMsgFirebase: ''
    }));
  };

  closeEmailSent = () => {
    this.setState({ isEmailSent: false });
    this.toggleResetPassword();
  };

  gotoLearn = () => {
    this.props.history.push('/');
  };

  gotoAchievement = () => {
    this.props.history.push('/achievementList');
  };

  gotoChatbot = () => {
    this.props.history.push('/chatbot');
  };

  gotoLeader = () => {
    this.props.history.push('/leader');
  };

  gotoProfile = () => {
    this.props.history.push('/profile');
  };

  gotoShop = () => {
    this.props.history.push('/shop');
    Analytic.log(Analytic.key.A5);
    Analytic.logGA(Analytic.key.A5, {
      category: Analytic.key.A5,
      action: 'Click'
    });
  };

  toggleScoreModal = isBananas => {
    const { isScoreModalOpen, isBananaScoreModalOpen } = this.state;
    if (isBananas) {
      if (!isScoreModalOpen) {
        this.setState({
          isScoreModalOpen: true,
          isBananaScoreModalOpen: true
        });
      } else {
        if (!isBananaScoreModalOpen) {
          this.setState({
            isBananaScoreModalOpen: true
          });
        } else {
          this.setState({
            isScoreModalOpen: false
          });
        }
      }
    } else {
      if (!isScoreModalOpen) {
        this.setState({
          isScoreModalOpen: true,
          isBananaScoreModalOpen: false
        });
      } else {
        if (isBananaScoreModalOpen) {
          this.setState({
            isBananaScoreModalOpen: false
          });
        } else {
          this.setState({
            isScoreModalOpen: false
          });
        }
      }
    }
  };

  _onNewsletterStateHandler = () =>
    this.setState({ isNewsletterEnable: !this.state.isNewsletterEnable });

  render() {
    const {
      isScrolled,
      bananas = 0,
      coins = 0,
      name,
      flagUri,
      photoURL,
      createAccount_txt_title,
      notification_text_confirm,
      notification_text_cancel,
      login_msg_loseProgressDialog,
      logout_txt,
      email_error_matchPasswords,
      email_error_fieldsMissing,
      email_error_longerPassword,
      email_error_wrongFormat,
      login_field_name,
      login_field_firstName,
      login_msg_reenterPassword,
      login_field_email,
      login_field_password,
      email_txt_loginNow,
      reset_password_txt_description,
      btn_continue,
      sign_up_txt_title,
      login_txt_title,
      email_txt_loginNow_link,
      already_register_txt,
      login_txt_forget_password_title,
      login_txt_forget_password_web,
      dont_have_account_yet_txt,
      at_least_6_password_txt,
      setting_txt_settingHeader,
      modal_error_faq,
      profile_txt_headerProfile,
      footer_txt_chatbot,
      footer_txt_learn,
      footer_txt_shop,
      isProUser,
      faq,
      showGift,
      promoCount,
      isLoginModalOpen,
      banana_score_modal_txt,
      star_score_modal_txt,
      modal_error_other,
      modal_error_answer_should_be_accepted,
      modal_error_answer_duplicate,
      modal_error_image_missing,
      modal_error_audio_not_match,
      modal_error_audio_missing,
      modal_error_audio_not_clear,
      modal_error_report_txt,
      modal_error_report,
      sign_in_opt_msg,
      pauseDialog,
      signInWithGoogle,
      signInWithFacebook,
      signInWithApple,
      sendPasswordResetEmail,
      txt_email_field_placeholder
    } = this.props;
    const {
      dropdownBtn,
      dropdownBtnActive,
      dropdownMenuStyle,
      dropdownItemStyle
    } = styles;
    const {
      dropdownOpen,
      pagePath,
      isScoreModalOpen,
      isBananaScoreModalOpen,
      modal,
      user,
      isNewsletterEnable
    } = this.state;
    const isHomePageActive =
      pagePath === '/' || pagePath === '/home' || pagePath === '/tutorial';
    const isChatbotPage =
      pagePath === '/chatbot' || pagePath.search('/conversation') !== -1;
    const isShopPage = pagePath === '/shop';
    let defaultProfilePics = [
      {
        id: 1,
        icon: `${PREFIX_PROFILE}avatar01.png`
      },
      {
        id: 2,
        icon: `${PREFIX_PROFILE}avatar02.png`
      },
      {
        id: 3,
        icon: `${PREFIX_PROFILE}avatar03.png`
      },
      {
        id: 4,
        icon: `${PREFIX_PROFILE}avatar04.png`
      },
      {
        id: 5,
        icon: `${PREFIX_PROFILE}avatar05.png`
      },
      {
        id: 6,
        icon: `${PREFIX_PROFILE}avatar06.png`
      }
    ];

    const newStyles = {
      button: {
        mr: 2,
        borderRadius: 2,
        backgroundColor: 'common.white',
        borderColor: 'primary.light',
        display: {
          md: 'inline-flex',
          xs: 'none'
        }
      },
      smallButton: {
        mr: 2,
        borderRadius: 2,
        borderWidth: 2,
        borderColor: 'primary.dark',
        boxShadow: 2,
        px: 0,
        display: {
          md: 'none',
          xs: 'block'
        },
        backgroundColor: 'common.white'
      },
      ghostButton: {
        borderRadius: 2,
        backgroundColor: 'primary.main',
        color: 'common.white',
        borderColor: 'common.white',
        mr: 8
      },
      title: {
        display: {
          md: 'block',
          xs: 'none'
        },
        mt: 1,
        mr: 0.5
      }
    };

    return (
      <MenuBar isScrolled={isScrolled}>
        {footer_txt_learn === 'footer_txt_learn' ? null : (
          <Fragment>
            <CenterButtons>
              <>
                <MaterialButton
                  startIcon={<SchoolOutlined />}
                  buttonText={footer_txt_learn}
                  sx={newStyles.button}
                  disabled={isHomePageActive}
                  clickAction={
                    isHomePageActive
                      ? null
                      : () =>
                          // eslint-disable-next-line
                          localStorage.getItem(SHOW_TUTORIAL) === TRUE
                            ? this.goToLink('/tutorial')
                            : this.goToLink('/')
                  }
                />
                <MaterialButton
                  buttonText={<SchoolOutlined />}
                  sx={newStyles.smallButton}
                  disabled={isHomePageActive}
                  clickAction={
                    isHomePageActive
                      ? null
                      : () =>
                          // eslint-disable-next-line
                          localStorage.getItem(SHOW_TUTORIAL) === TRUE
                            ? this.goToLink('/tutorial')
                            : this.goToLink('/')
                  }
                />
              </>
              <>
                <MaterialButton
                  startIcon={<Chat />}
                  buttonText={footer_txt_chatbot}
                  clickAction={
                    isChatbotPage ? null : () => this.goToLink('/chatbot')
                  }
                  disabled={isChatbotPage}
                  sx={newStyles.button}
                />
                <MaterialButton
                  buttonText={<Chat />}
                  clickAction={
                    isChatbotPage ? null : () => this.goToLink('/chatbot')
                  }
                  disabled={isChatbotPage}
                  sx={newStyles.smallButton}
                />
              </>
              {!isProUser && (
                <>
                  <MaterialButton
                    startIcon={<ShoppingCartOutlined />}
                    onClick={
                      !isShopPage
                        ? () => {
                            this.goToLink('/shop', 'shop_page');
                            Analytic.logGA(Analytic.key.A5, {
                              category: Analytic.key.A5,
                              action: 'Click'
                            });
                          }
                        : null
                    }
                    disabled={isShopPage}
                    buttonText={footer_txt_shop}
                    sx={newStyles.button}
                  />
                  <MaterialButton
                    buttonText={<ShoppingCartOutlined />}
                    onClick={
                      !isShopPage
                        ? () => {
                            this.goToLink('/shop', 'shop_page');
                            Analytic.logGA(Analytic.key.A5, {
                              category: Analytic.key.A5,
                              action: 'Click'
                            });
                          }
                        : null
                    }
                    disabled={isShopPage}
                    sx={newStyles.smallButton}
                  />
                </>
              )}
            </CenterButtons>
            <CenterHeaderButtonContainer>
              <FlagContainer onClick={() => this.goToLink('/languagepicker')}>
                <CircleIcon src={flagUri} />
              </FlagContainer>
              <FlagContainer>
                <CircleIcon
                  onClick={() => this.goToLink('/profile')}
                  src={getProfileImage(photoURL, defaultProfilePics)}
                />
              </FlagContainer>
              {name !== 'Anonymous' ? (
                <Fragment>
                  <Box sx={newStyles.title}>{name}</Box>
                  <Dropdown isOpen={dropdownOpen} toggle={this.toggle1}>
                    <DropdownToggle
                      style={dropdownOpen ? dropdownBtn : dropdownBtnActive}
                    >
                      {dropdownOpen ? (
                        <Icon src={`${PREFIX_HEADER}back-yellow-icon.svg`} />
                      ) : (
                        <Icon src={`${PREFIX_HEADER}back-white-icon.svg`} />
                      )}
                    </DropdownToggle>
                    <DropdownMenu style={dropdownMenuStyle}>
                      <DropdownItem
                        style={dropdownItemStyle}
                        className="dropdownItemClass"
                        onClick={() => this.goToLink('/profile')}
                      >
                        {profile_txt_headerProfile}
                      </DropdownItem>
                      <Divider />
                      <DropdownItem
                        style={dropdownItemStyle}
                        className="dropdownItemClass"
                        onClick={() => this.goToLink('/setting')}
                      >
                        {setting_txt_settingHeader}
                      </DropdownItem>
                      <Divider />
                      <DropdownItem
                        style={dropdownItemStyle}
                        className="dropdownItemClass"
                        onClick={this.toggleErrorReport}
                      >
                        {modal_error_faq}
                      </DropdownItem>
                      <Divider />

                      <DropdownItem
                        style={dropdownItemStyle}
                        className="dropdownItemClass"
                        onClick={this.toggleLogOut}
                      >
                        {logout_txt}
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  <ScoreContainer id={'score-modal-id'}>
                    <SquareIcon
                      onClick={() => this.toggleScoreModal(IS_BANANAS)}
                      src={`${PREFIX_HEADER}banana-squre-white.svg`}
                    />
                    {isScoreModalOpen && isBananaScoreModalOpen && (
                      <ScoreModal
                        score_modal_txt={banana_score_modal_txt}
                        isBanana={true}
                      />
                    )}
                  </ScoreContainer>
                  <Score>{bananas}</Score>

                  <ScoreContainer id={'score-modal-id1'}>
                    <SquareIcon
                      onClick={() => this.toggleScoreModal(!IS_BANANAS)}
                      src={`${PREFIX_HEADER}star-squre-white.svg`}
                    />

                    {isScoreModalOpen && !isBananaScoreModalOpen && (
                      <ScoreModal
                        score_modal_txt={star_score_modal_txt}
                        isBanana={false}
                      />
                    )}
                  </ScoreContainer>
                  <Score>{coins}</Score>
                </Fragment>
              ) : (
                <Fragment>
                  <Box sx={newStyles.title}>{name}</Box>
                  <Dropdown isOpen={dropdownOpen} toggle={this.toggle1}>
                    <DropdownToggle
                      style={dropdownOpen ? dropdownBtn : dropdownBtnActive}
                    >
                      {dropdownOpen ? (
                        <Icon src={`${PREFIX_HEADER}back-yellow-icon.svg`} />
                      ) : (
                        <Icon src={`${PREFIX_HEADER}back-white-icon.svg`} />
                      )}
                    </DropdownToggle>
                    <DropdownMenu style={dropdownMenuStyle}>
                      <DropdownItem
                        style={dropdownItemStyle}
                        className="dropdownItemClass"
                        onClick={() => this.goToLink('/setting')}
                      >
                        {setting_txt_settingHeader}
                      </DropdownItem>
                      <Divider />
                      <DropdownItem
                        style={dropdownItemStyle}
                        className="dropdownItemClass"
                        onClick={this.toggleErrorReport}
                      >
                        {modal_error_faq}
                      </DropdownItem>
                      <Divider />

                      <DropdownItem
                        style={dropdownItemStyle}
                        className="dropdownItemClass"
                        onClick={this.toggleLogin}
                      >
                        {login_txt_title}
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  <MaterialButton
                    buttonText={createAccount_txt_title}
                    clickAction={() => this.toggle()}
                    variant="outlined"
                    size="small"
                    sx={newStyles.ghostButton}
                  />
                  <SignUpModal
                    sendPasswordResetEmail={sendPasswordResetEmail}
                    signInWithGoogle={signInWithGoogle}
                    signInWithFacebook={signInWithFacebook}
                    signInWithApple={signInWithApple}
                    modal={modal}
                    user={user}
                    history={this.props.history}
                    isNewsletterEnable={isNewsletterEnable}
                    _onNewsletterStateHandler={this._onNewsletterStateHandler}
                    stateChangeHandler={this.stateChangeHandler}
                    pauseDialog={pauseDialog}
                    isOpen={modal}
                    toggle={this.toggle}
                    isLoading={this.state.isLoading}
                    isResetPassword={this.state.isResetPassword}
                    toggleResetPassword={this.toggleResetPassword}
                    isSignUp={this.state.isSignUp && !isLoginModalOpen}
                    toggleSignUp={this.toggleSignUp}
                    login={this.login}
                    fbLogin={this.fbLogin}
                    googleLogin={this.googleLogin}
                    appleLogin={this.appleLogin}
                    signUpWithEmail={this.signUpWithEmail}
                    errorMsgFirebase={this.state.errorMsgFirebase}
                    resetPassword={this.resetPassword}
                    sign_in_opt_msg={sign_in_opt_msg}
                    email_error_matchPasswords={email_error_matchPasswords}
                    email_error_fieldsMissing={email_error_fieldsMissing}
                    email_error_longerPassword={email_error_longerPassword}
                    email_error_wrongFormat={email_error_wrongFormat}
                    login_field_name={login_field_name}
                    login_field_firstName={login_field_firstName}
                    login_msg_reenterPassword={login_msg_reenterPassword}
                    login_field_email={login_field_email}
                    login_field_password={login_field_password}
                    email_txt_loginNow={email_txt_loginNow}
                    resetErrorMsg={this.resetErrorMsg}
                    successMsg={this.state.successMsg}
                    resetUser={this.resetUser}
                    btn_continue={btn_continue}
                    isEmailSent={this.state.isEmailSent}
                    reset_password_txt_description={
                      reset_password_txt_description
                    }
                    closeEmailSent={this.closeEmailSent}
                    sign_up_txt_title={sign_up_txt_title}
                    login_txt_title={login_txt_title}
                    email_txt_loginNow_link={email_txt_loginNow_link}
                    already_register_txt={already_register_txt}
                    login_txt_forget_password_title={
                      login_txt_forget_password_title
                    }
                    login_txt_forget_password_web={
                      login_txt_forget_password_web
                    }
                    dont_have_account_yet_txt={dont_have_account_yet_txt}
                    at_least_6_password_txt={at_least_6_password_txt}
                    txt_email_field_placeholder={txt_email_field_placeholder}
                  />
                </Fragment>
              )}
              {showGift && !isProUser && (
                <GiftIconContainer
                  onClick={() => {
                    this.goToLink('/promo_shop');
                    Analytic.logGA(Analytic.key.A11, {
                      category: Analytic.key.A11,
                      action: 'Click'
                    });
                  }}
                >
                  <img src={giftIcon} alt="" />
                  <NotificationContainer>
                    <NotificationText>{promoCount}</NotificationText>
                  </NotificationContainer>
                </GiftIconContainer>
              )}
              <ReportModal
                modal_error_other={modal_error_other}
                modal_error_answer_should_be_accepted={
                  modal_error_answer_should_be_accepted
                }
                modal_error_answer_duplicate={modal_error_answer_duplicate}
                modal_error_image_missing={modal_error_image_missing}
                modal_error_audio_not_match={modal_error_audio_not_match}
                modal_error_audio_missing={modal_error_audio_missing}
                modal_error_audio_not_clear={modal_error_audio_not_clear}
                modal_error_report_txt={modal_error_report_txt}
                modal_error_report={modal_error_report}
                modal_error_faq={modal_error_faq}
                isOpen={this.state.isReportError}
                toggleReport={this.toggleErrorReport}
                faq={faq}
                btn_continue={btn_continue}
              />
              <QuitModal
                isOpen={this.state.isLogOut}
                toggle={this.toggleLogOut}
                isLogOut={this.state.isLogOut}
                logout={this.logOut}
                isLoading={this.state.isLoading}
                notification_text_confirm={notification_text_confirm}
                notification_text_cancel={notification_text_cancel}
                login_msg_loseProgressDialog={login_msg_loseProgressDialog}
                logout_txt={logout_txt}
                goToHome={() => {
                  Analytic.logGA(Analytic.key.L2, {
                    category: Analytic.key.L2,
                    action: 'Click'
                  });
                  this.goToLink('/');
                }}
              />
            </CenterHeaderButtonContainer>
          </Fragment>
        )}
      </MenuBar>
    );
  }
}
const HeaderMenuWithRouter = withRouter(HeaderMenu);
export { HeaderMenuWithRouter };
