/* @flow */
import * as React from 'react';
import Analytic from '../../../util/Analytic';
import { IMAGE_PATH, OFFER, REDEEM } from '../../../common/constants';
import { mutliStringReplace, setReferrerCode } from '../../../common/actions';
import styled from 'styled-components';
import _ from 'lodash';
import BugTracker from '../../../util/BugTracker';
import { store } from '../../../index';
import { firebase } from '../../../system/Firebase';
import axios from 'axios';
import { fetchRemoteDatabase } from '../../../lessons/sagas';
import {
  getLocalStorage,
  getStripePromoCodeId,
  removeLocalStorage,
  LOCAL_STORAGE_CONSTANTS
} from '../../../util';
import { trackFacebookPixelPurchase } from '../../../util/FacebookPixel';
import ThankYouPage from './ThankYouPage';
import { createPaypalSubscription } from '../../../util/StripePaymentProfilePageHelper';
import { getStripeProductId } from '../../../util/StripeProductsHandlers';

// To be sure this is only called once (Can call at most 1 time per minute)
const createPaypalSubscriptionThrottled = _.throttle(
  createPaypalSubscription,
  60000
);

type Props = {
  history: Object,
  processing_payment_wait_txt: string,
  afterBuy_txt_body: string,
  afterBuy_txt_title: string,
  writing_txt_next: string,
  afterBuy_txt_title: string,
  txt_dsc_download_app: string,
  txt_dsc_learning_in_browser: string,
  btn_continue_in_browser: string,
  location: Object,
  saveUserData: Function,
  updateUserData: Function,
  createNewDatabaseUser: Function,
  addLoginListener: Function,
  triggerLoadLanguageLabel: Function,
  isUserSubscribed: boolean,
  selectedPurchaseProduct: Object,
  prices: {
    monthlyPrice: Object,
    quarterlyPrice: Object,
    yearlyPrice: Object,
    monthlyPriceStripeUk: Object,
    quarterlyPriceStripeUk: Object,
    yearlyPriceStripeUk: Object
  },
  tracker: any,
  match: {
    isExact: boolean,
    params: {
      path: string,
      url: string
    }
  },
  uid: string
};
type State = {
  productName: string,
  paymentSuccess: boolean
};

const LingLoading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
`;

const lingLoading = `${IMAGE_PATH}ling-loader.gif`;
class ThankYouScene extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      productName: '',
      paymentSuccess: false
    };
    this.goHome = this.goHome.bind(this);
  }

  isPaypalCheckout() {
    return this.props.location.search.includes('checkoutType=paypal');
  }

  getDataForAnalytics() {
    const { selectedPurchaseProduct } = this.props;
    const {
      currency,
      priceUnitAmount,
      productName,
      stripeId,
      sourceScreen
    } = selectedPurchaseProduct;
    return [currency, priceUnitAmount, productName, stripeId, sourceScreen];
  }

  fetchUser(user: Object, receipt: any = false) {
    fetchRemoteDatabase(`users/${user.uid}`).then(async data => {
      const {
        saveUserData,
        createNewDatabaseUser,
        updateUserData
      } = this.props;
      const value = data.val();
      if (value) {
        saveUserData(value);
      } else {
        createNewDatabaseUser(user, 'anonymous');
      }

      if (receipt) {
        const { productName, receipt: ReceiptData } = receipt;
        const subscription = _.get(
          store.getState(),
          'storage.user.subscription',
          {}
        );
        if (productName && productName.split('_').shift() === OFFER) {
          const receiptInput = {
            subscription: {
              ...subscription,
              purchase_date: Date.parse(new Date().toISOString()),
              activeSubscription: productName
                .split('_')
                .join('.')
                .substring(
                  productName
                    .split('_')
                    .join('.')
                    .indexOf('.') + 1
                ),
              purchaseHistory: {
                ..._.get(subscription, 'purchaseHistory', {}),
                [productName.substring(productName.indexOf('_') + 1)]: {
                  productId: productName.substring(
                    productName.indexOf('_') + 1
                  ),
                  ReceiptData,
                  type: 'stripe'
                }
              }
            },
            msg: {
              productName: productName,
              order_no: receipt.receipt
            }
          };
          const functions = process.env.REACT_APP_FUNCTIONS || '';
          axios
            .post(
              `${functions}/validateLingWeb-stripeApi/app/${user.uid}`,
              receiptInput
            )
            .then(data => {
              this.setState({
                paymentSuccess: true,
                productName: productName.split('_').shift()
              });
            })
            .catch(error => {
              console.log('error: do parsing', error);
            });

          updateUserData(receiptInput);
        } else if (productName && productName.split('_').shift() === REDEEM) {
          const functions = process.env.REACT_APP_FUNCTIONS || '';
          axios
            .post(`${functions}/validateLingWeb-stripeApi/session`, {
              sessionId: ReceiptData,
              redeemType: productName.split('_')[1]
            })
            .then(data => {
              this.setState({
                paymentSuccess: true,
                productName: productName.split('_').shift()
              });
            })
            .catch(error => {
              console.log('error: do parsing', error);
            });
        } else {
          if (productName) {
            const receiptInput = {
              subscription: {
                ...subscription,
                purchase_date: Date.parse(new Date().toISOString()),
                activeSubscription: productName.split('_').join('.'),
                purchaseHistory: {
                  ..._.get(subscription, 'purchaseHistory', {}),
                  [productName]: {
                    productId: productName,
                    ReceiptData,
                    type: 'stripe'
                  }
                }
              },
              msg: {
                productName: productName,
                order_no: receipt.receipt
              }
            };
            const functions = process.env.REACT_APP_FUNCTIONS || '';
            axios
              .post(
                `${functions}/validateLingWeb-stripeApi/app/${user.uid}`,
                receiptInput
              )
              .then(data => {
                this.setState({
                  paymentSuccess: true
                });
              })
              .catch(error => {
                console.log('error: do parsing', error);
              });
            updateUserData({ subscription: receiptInput.subscription });
          }
        }
      }
    });
  }

  async componentDidMount() {
    const { search } = this.props.location;
    const { triggerLoadLanguageLabel } = this.props;
    const receipt = _.get(this.props, 'match.params.receipt', false);
    const promoName = _.get(this.props, 'match.params.promoName', false);
    const analyticsData = this.getDataForAnalytics();
    const currency = analyticsData[0];
    const price = analyticsData[1] / 100;
    const productNameFromSelectedProduct = analyticsData[2];
    const stripeId = analyticsData[3];
    const sourceScreen = analyticsData[4];
    const queryPromoCode = search && search.match(/promoCode=([^&]*)/);

    const productName = this.isPaypalCheckout()
      ? productNameFromSelectedProduct
      : _.get(this.props, 'match.params.type', false);

    const priceName = this.isPaypalCheckout()
      ? `${this.props.selectedPurchaseProduct.productName}PriceStripeUk`
      : `${productName}Price`;

    const localPrices =
      this.props.prices[priceName]?.local ?? this.props.prices[priceName]?.usd;

    if (queryPromoCode) {
      const promoCode = queryPromoCode[1];
      const { osi } = await getStripePromoCodeId(promoCode);
      setReferrerCode(osi);
    }
    this.setState({
      productName: productName.split('_').shift()
    });
    let auth = firebase.auth();
    Analytic.log(Analytic.key.A2, {
      category: Analytic.key.A2,
      action: 'Check'
    });

    Analytic.logGA(Analytic.key.A2, {
      category: Analytic.key.A2,
      action: 'Check'
    });

    const trackPurchaseConfirm = () => {
      console.log('Checking.,.,.,.,.,.,,.', this.props.history);
      trackFacebookPixelPurchase(price, currency, productName === 'yearly');
      if (productName === 'lifetime') {
        this.props.tracker.requestTracking(
          'purchase_confirm',
          {
            source:
              this.props.history.location.state.source ||
              this.props.history.location.source ||
              'onboarding',
            screen_type: 'shop_screen',
            plan: productName,
            plan_amount: String(price),
            proceeds_amount: String(price),
            currency: currency,
            vendor_product_id: getStripeProductId(currency, 'lifetime', {}),
            purchase_time: new Date().toISOString(),
            store: 'web',
            paywall_name: 'Standard'
          },
          {
            purchased_from_source_screen: sourceScreen,
            trial_duration: 0
          }
        );
      } else {
        this.props.tracker.requestTracking(
          'purchase_confirm',
          {
            source:
              this.props.history.location.state.source ||
              this.props.history.location.source ||
              'onboarding',
            screen_type: 'shop_screen',
            plan: productName,
            plan_amount: localPrices.price,
            proceeds_amount: localPrices.price,
            currency: localPrices.currency,
            plan_amount_usd: this.props.prices[priceName].usd.price,
            proceeds_amount_usd: this.props.prices[priceName].usd.price,
            vendor_product_id: localPrices.productId,
            purchase_time: new Date().toISOString(),
            store: 'web',
            paywall_name: 'Standard'
          },
          {
            purchased_from_source_screen: sourceScreen,
            trial_duration: this.props.prices[priceName].usd.trialPeriodDays
          }
        );
      }
    };

    const redirectStatus = this.isPaypalCheckout()
      ? search && search.match(/redirect_status=([^&]*)/)[1]
      : 'succeeded'; // Note: Original logic for non-Paypal is to always fire, keeping it this way for now
    if (redirectStatus === 'succeeded') {
      trackPurchaseConfirm();
    }

    if (this.isPaypalCheckout()) {
      // Note: Paypal also provides these, but we don't use yet
      // const setupIntentClientSecret = search && search.match(/setup_intent_client_secret=([^&]*)/)[1];
      const setupIntent = this.props.location.search.match(
        /setup_intent=([^&]*)/
      )[1];
      if (redirectStatus === 'succeeded') {
        const { success, error } = await createPaypalSubscriptionThrottled(
          this.props.uid,
          setupIntent,
          this.props.selectedPurchaseProduct.stripeId
        );

        if (error) {
          console.error(error);
          this.setState({
            paymentSuccess: false
          });
          alert(`${error}!\nPlease try again!`);
          this.props.history.replace('/paywall');
        }

        if (success) {
          this.setState({
            paymentSuccess: true
          });
        }
      } else {
        const message = 'Failed to link PayPal account!';
        console.error(message);
        alert(message);
        this.props.history.replace('/paywall');
      }
    }

    auth.onAuthStateChanged(user => {
      if (!user) {
        auth
          .signInAnonymously()
          .then(result => {
            const { user } = result;
            this.fetchUser(user);
          })
          .catch(error => {
            // Handle Errors here.
            var errorCode = error.code;

            if (errorCode === 'auth/operation-not-allowed') {
              alert('You must enable Anonymous auth in the Firebase Console.');
            }
            BugTracker.notify(error);
          });
      } else {
        console.log('Create User Listener', user);
        this.props.addLoginListener(user);
        this.fetchUser(user, { receipt, productName });
        //Don't push to dataLayer if price 0, such as when refresh/access from bookmark
        if (price !== 0) {
          pushToDataLayer(user);
        }
      }
    });

    if (!this.isPaypalCheckout()) {
      triggerLoadLanguageLabel();
    }

    function pushToDataLayer(user) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'purchase_ga-ua',
        event_action: 'purchase',
        event_category: 'ecommerce',
        event_label: receipt,
        event_value: price,
        userId: user.uid,
        ecommerce: {
          currencyCode: currency,
          purchase: {
            actionField: {
              affiliation: 'Web',
              coupon: promoName,
              id: receipt,
              revenue: price
            },
            products: [
              {
                name: productName,
                id: stripeId,
                price: price,
                category: 'subscription',
                variant: '',
                quantity: 1
              }
            ]
          }
        }
      });

      window.dataLayer.push({
        event: 'purchase_ga4',
        userId: user.uid,
        ecommerce: {
          affiliation: 'Web',
          coupon: promoName,
          currency: currency,
          items: [
            {
              affiliation: 'Web',
              coupon: promoName,
              currency: currency,
              discount: '',
              index: 0,
              item_brand: 'ling-app',
              item_category: 'subscription',
              item_id: stripeId,
              item_name: productName,
              price: price,
              quantity: 1
            }
          ],
          transaction_id: receipt,
          value: price
        }
      });
    }
  }

  goHome = () => {
    this.props.history.push('/');
  };

  goToMobileThankYou = () => {
    const { isUserSubscribed } = this.props;
    const { productName } = this.state;

    if (!isUserSubscribed) {
      BugTracker.notify('User tried to purchase but no success');
    }
    window.location =
      productName.split('_').shift() === REDEEM
        ? 'https://ling-app.com/thank-you-purchase-gift/'
        : 'https://ling-app.com/thank-you/';
    this.setState({
      paymentSuccess: false
    });
  };

  goToPreSavedThankYouUrl = () => {
    const redirectedThankyouURL = getLocalStorage(
      LOCAL_STORAGE_CONSTANTS.REDIRECT_THANKYOU_URL
    );
    removeLocalStorage(LOCAL_STORAGE_CONSTANTS.REDIRECT_THANKYOU_URL);
    return window.location.replace(redirectedThankyouURL);
  };

  render() {
    const {
      // afterBuy_txt_title,
      // afterBuy_txt_body,
      // writing_txt_next,
      isUserSubscribed,
      processing_payment_wait_txt,
      afterBuy_txt_title,
      txt_dsc_download_app,
      txt_dsc_learning_in_browser,
      btn_continue_in_browser
    } = this.props;
    const { productName, paymentSuccess } = this.state;
    // const redirectedThankyouURL = getLocalStorage(
    //   LOCAL_STORAGE_CONSTANTS.REDIRECT_THANKYOU_URL
    // );

    if (this.isPaypalCheckout() && this.state.paymentSuccess) {
      return (
        <ThankYouPage
          labels={{
            afterBuy_txt_title,
            txt_dsc_download_app,
            txt_dsc_learning_in_browser,
            btn_continue_in_browser
          }}
          buttonAction={() => this.goHome()}
        />
      );
    }

    const isPaymentPage =
      window.location.pathname.indexOf('payment') > -1 &&
      navigator.userAgent.toLowerCase().indexOf('mobile') > -1 &&
      paymentSuccess;
    const isLoading =
      productName === REDEEM ? !paymentSuccess : !isUserSubscribed;

    return isLoading ||
      (navigator.userAgent.toLowerCase().indexOf('mobile') > -1 &&
        !isPaymentPage &&
        !isUserSubscribed) ? (
      <LingLoading>
        <img src={lingLoading} alt={lingLoading} />
        <p
          dangerouslySetInnerHTML={{
            __html: mutliStringReplace(
              {
                indent: '<br />'
              },
              processing_payment_wait_txt
            )
          }}
        />
      </LingLoading>
    ) : (
      <ThankYouPage
        labels={{
          afterBuy_txt_title,
          txt_dsc_download_app,
          txt_dsc_learning_in_browser,
          btn_continue_in_browser
        }}
        buttonAction={() => this.goHome()}
      />
    );
  }
}

export { ThankYouScene };
