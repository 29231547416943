/* @flow */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  updatePaymentMethod,
  getUserSubscriptionDataDetail,
  getStripeYearlyPrice,
  createPaymentMethodAndCustomerShop,
  getPriceNumber,
  onReactivateSubscription,
  getUserManageSubscriptionText,
  onToggleIsCheckout,
  toggleLoginPurchase,
  getCurrency,
  findCurrency
} from '../../../util';
import { CheckoutForm } from '../../../shop/components/main-components/CheckoutForm/CheckoutForm';
import DefaultHeaderContainer from '../../../common/containers/DefaultHeaderContainer';
import { IMAGE_PATH } from '../../../common/constants';
import { ManageSubscriptionSection } from '../ManageSubscriptionSection/ManageSubscriptionSection';
import './../SettingScene.css';
import { SpinnerContainer } from './ManageSubscriptionScene.styles';
type Props = {
  btn_submit: string,
  cancel_subscription_txt_profile: string,
  card_error_empty: string,
  card_error_invalid_cvc: string,
  card_error_invalid_date: string,
  card_error_number_not_correct: string,
  card_expiration: string,
  card_number: string,
  createAccount_txt_title: string,
  currentEffectEnabled: boolean,
  currentVoiceSpeed: number,
  email_error_empty: string,
  email_error_wrongFormat: string,
  footer_txt_profile: string,
  functions: string,
  history: Object,
  isDev: boolean,
  isProUser: boolean,
  location: Object,
  loginPurchase_txt: string,
  login_field_email: string,
  login_field_name: string,
  logout_txt: string,
  name: string,
  nativeLangCode: string,
  photoURL: string,
  profile_txt_cancel_comfirm: string,
  profile_txt_cancel_denies: string,
  profile_txt_cancel_subscription: string,
  profile_txt_cancel_subscription_des: string,
  profile_txt_card_number: string,
  profile_txt_charged: string,
  profile_txt_current_plan: string,
  profile_txt_expire_date: string,
  profile_txt_for: string,
  profile_txt_lifetime_plan: string,
  profile_txt_name_card: string,
  profile_txt_next_payment: string,
  profile_txt_of: string,
  profile_txt_on: string,
  profile_txt_past_due_msg: string,
  profile_txt_payment_method: string,
  profile_txt_plan: string,
  profile_txt_reactivate: string,
  profile_txt_recurring_billing: string,
  profile_txt_subscription_expire: string,
  profile_txt_success_dec: string,
  profile_txt_success_title: string,
  profile_txt_terms_condition: string,
  profile_txt_visa_expire: string,
  purchase_button_subscribe_now: string,
  purchase_txt_benefit_details_01: string,
  random_test_modal_back_txt: string,
  random_test_modal_confirm_txt: string,
  setting_txt_settingHeader: string,
  profile_txt_past_due_cancel_modal_des: string,
  saveChange: Function,
  setTrialUsedIP: Function,
  shop_txt_7_day_trial: string,
  shop_txt_checkout_title_top: string,
  shop_txt_checkout_title_yearly: string,
  shop_txt_checkout_title_monthly: string,
  shop_txt_checkout_title_lifetime: string,
  profile_txt_1_year_ling_pro: string,
  profile_txt_1_month_ling_pro: string,
  signOut: Function,
  targetLangCode: string,
  trial_date_description_txt: string,
  unitObject: Object,
  updateUserProfile: Function,
  update_subscription_methob_txt_profile: string,
  user: Object,
  userInfo: Object,
  tracker: any
};
type State = {
  coupon: string,
  currency: string,
  currencySymbol: Object,
  currencySymbol: Object,
  dropdownOpen: boolean,
  isChange: boolean,
  isCheckout: boolean,
  isDeleteAcc: boolean,
  isDeleteSuccess: boolean,
  isDeleteSuccess: boolean,
  isEffect: boolean,
  isError: boolean,
  isLoginPurchase: boolean,
  isLoginPurchase: boolean,
  isRedeemSuccess: boolean,
  isUpdateMethod: boolean,
  isUpdatingCard: boolean,
  itemInfo: Array<Object>,
  productId: string,
  productName: string,
  soundSpeed: number,
  subscriptionData: any,
  usdYearPrice: number,
  validCardCvc: boolean,
  validCardExpiry: boolean,
  validCardNumber: boolean,
  yearPrice: number
};
const lingLoading = `${IMAGE_PATH}ling-loader.gif`;

class ManageSubscriptionScene extends Component<Props, State> {
  child: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      subscriptionData: {},
      isEffect: true,
      dropdownOpen: false,
      soundSpeed: 1,
      coupon: '',
      isChange: false,
      isRedeemSuccess: false,
      isError: false,
      isLoginPurchase: false,
      isDeleteAcc: false,
      isDeleteSuccess: false,
      isUpdateMethod: false,
      error: true,
      validCardNumber: false,
      validCardExpiry: false,
      validCardCvc: false,
      isUpdatingCard: false,
      yearPrice: 7999,
      usdYearPrice: 7999,
      currency: '',
      currencySymbol: {},
      itemInfo: [],
      productName: '',
      productId: '',
      isCheckout: false
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const {
      currentEffectEnabled,
      currentVoiceSpeed,
      functions,
      updateUserProfile,
      user
    } = this.props;
    const language = window.navigator.language;
    const currency = getCurrency(language)
      ? getCurrency(language).toUpperCase()
      : 'USD';
    this.setState({
      currency: getCurrency(language),
      currencySymbol: findCurrency((currency ? currency : 'usd').toUpperCase()),
      isEffect: currentEffectEnabled,
      soundSpeed: currentVoiceSpeed
    });
    getUserSubscriptionDataDetail(
      user,
      functions,
      updateUserProfile,
      this.manageSceneStateChangeHandler
    );
    getStripeYearlyPrice(
      functions,
      currency,
      user,
      this.manageSceneStateChangeHandler
    );
  }
  manageSceneStateChangeHandler = (name: string, value: any) => {
    this.setState({
      [name]: value
    });
  };

  toggleCreateAccount = () => {
    const {
      productId,
      productName,
      currencySymbol,
      usdYearPrice,
      yearPrice,
      isLoginPurchase
    } = this.state;
    toggleLoginPurchase(isLoginPurchase, this.manageSceneStateChangeHandler);
    this.child.toggleCreateAccount(
      productId,
      productName,
      (yearPrice !== 0 ? yearPrice : usdYearPrice) / 100,
      currencySymbol.code
    );
  };

  render() {
    const {
      history,
      location,
      setting_txt_settingHeader,
      profile_txt_reactivate,
      logout_txt,
      signOut,
      footer_txt_profile,
      userInfo,
      isDev,
      name,
      photoURL,
      purchase_txt_benefit_details_01,
      shop_txt_7_day_trial,
      cancel_subscription_txt_profile,
      profile_txt_current_plan,
      random_test_modal_confirm_txt,
      update_subscription_methob_txt_profile,
      profile_txt_next_payment,
      profile_txt_visa_expire,
      profile_txt_payment_method,
      profile_txt_expire_date,
      profile_txt_name_card,
      profile_txt_card_number,
      btn_submit,
      profile_txt_subscription_expire,
      profile_txt_cancel_subscription,
      profile_txt_terms_condition,
      profile_txt_recurring_billing,
      random_test_modal_back_txt,
      shop_txt_checkout_title_yearly,
      shop_txt_checkout_title_monthly,
      shop_txt_checkout_title_lifetime,
      shop_txt_checkout_title_top,
      profile_txt_on,
      profile_txt_charged,
      profile_txt_for,
      profile_txt_of,
      profile_txt_success_title,
      profile_txt_success_dec,
      profile_txt_cancel_comfirm,
      profile_txt_cancel_denies,
      profile_txt_cancel_subscription_des,
      login_field_name,
      card_number,
      card_expiration,
      card_error_empty,
      card_error_number_not_correct,
      card_error_invalid_date,
      card_error_invalid_cvc,
      trial_date_description_txt,
      profile_txt_plan,
      profile_txt_lifetime_plan,
      createAccount_txt_title,
      loginPurchase_txt,
      email_error_empty,
      email_error_wrongFormat,
      login_field_email,
      isProUser,
      user,
      functions,
      setTrialUsedIP,
      purchase_button_subscribe_now,
      profile_txt_1_year_ling_pro,
      profile_txt_1_month_ling_pro,
      profile_txt_past_due_cancel_modal_des,
      profile_txt_past_due_msg,
      updateUserProfile
    } = this.props;
    const {
      isDeleteAcc,
      isDeleteSuccess,
      subscriptionData,
      isUpdateMethod,
      validCardNumber,
      validCardExpiry,
      productName,
      validCardCvc,
      isUpdatingCard,
      isCheckout,
      currency,
      yearPrice,
      usdYearPrice,
      isLoginPurchase,
      productId,
      currencySymbol
    } = this.state;
    const userSubscribedData = getUserManageSubscriptionText(
      user,
      subscriptionData,
      currency,
      profile_txt_plan,
      profile_txt_on,
      profile_txt_for,
      profile_txt_lifetime_plan,
      profile_txt_charged,
      profile_txt_of,
      shop_txt_checkout_title_monthly,
      shop_txt_checkout_title_yearly,
      shop_txt_checkout_title_lifetime,
      profile_txt_1_year_ling_pro,
      profile_txt_1_month_ling_pro,
      profile_txt_past_due_msg
    );
    return (
      <Fragment>
        <DefaultHeaderContainer
          isSrollable={false}
          history={history}
          location={location}
          onRef={ref => (this.child = ref)}
          toggleCreateAccount
          toggleCheckout={() =>
            onToggleIsCheckout(
              isProUser,
              history,
              isCheckout,
              this.manageSceneStateChangeHandler
            )
          }
        />
        {isCheckout ? (
          <Fragment>
            {isUpdatingCard ? (
              <SpinnerContainer>
                <img src={lingLoading} alt={lingLoading} />
              </SpinnerContainer>
            ) : (
              <CheckoutForm
                trial_date_description_txt={trial_date_description_txt}
                packageName={'yearly'}
                isClaim={false}
                email_error_empty={email_error_empty}
                email_error_wrongFormat={email_error_wrongFormat}
                login_field_email={login_field_email}
                login_field_name={login_field_name}
                card_number={card_number}
                card_expiration={card_expiration}
                card_error_empty={card_error_empty}
                card_error_number_not_correct={card_error_number_not_correct}
                card_error_invalid_date={card_error_invalid_date}
                card_error_invalid_cvc={card_error_invalid_cvc}
                functions={functions}
                shop_txt_checkout_price={getPriceNumber(
                  yearPrice,
                  usdYearPrice,
                  currencySymbol
                )}
                toggleIsCheckout={() =>
                  onToggleIsCheckout(
                    isProUser,
                    history,
                    isCheckout,
                    this.manageSceneStateChangeHandler
                  )
                }
                shop_txt_checkout_title={shop_txt_checkout_title_yearly}
                shop_txt_checkout_title_top={shop_txt_checkout_title_top}
                random_test_modal_back_txt={random_test_modal_back_txt}
                profile_txt_name_card={profile_txt_name_card}
                profile_txt_card_number={profile_txt_card_number}
                profile_txt_expire_date={profile_txt_expire_date}
                profile_txt_recurring_billing={profile_txt_recurring_billing}
                createPaymentMethodAndCustomer={cardNumber =>
                  createPaymentMethodAndCustomerShop(
                    cardNumber,
                    user,
                    isDev,
                    history,
                    currency,
                    productId,
                    isUpdatingCard,
                    isLoginPurchase,
                    productName,
                    functions,
                    this.manageSceneStateChangeHandler
                  )
                }
                profile_txt_terms_condition={profile_txt_terms_condition}
                btn_submit={btn_submit}
                setTrialUsedIP={setTrialUsedIP}
              />
            )}
          </Fragment>
        ) : (
          <ManageSubscriptionSection
            updateUserProfile={updateUserProfile}
            random_test_modal_back_txt={random_test_modal_back_txt}
            profile_txt_success_title={profile_txt_success_title}
            profile_txt_cancel_subscription_des={
              profile_txt_cancel_subscription_des
            }
            profile_txt_cancel_denies={profile_txt_cancel_denies}
            profile_txt_cancel_comfirm={profile_txt_cancel_comfirm}
            onClick={() =>
              updatePaymentMethod(
                false,
                functions,
                isUpdatingCard,
                isUpdateMethod,
                user,
                this.manageSceneStateChangeHandler
              )
            }
            profile_txt_past_due_cancel_modal_des={
              profile_txt_past_due_cancel_modal_des
            }
            isUpdateMethod={isUpdateMethod}
            isUpdatingCard={isUpdatingCard}
            createAccount_txt_title={createAccount_txt_title}
            loginPurchase_txt={loginPurchase_txt}
            isLoginPurchase={isLoginPurchase}
            manageSceneStateChangeHandler={this.manageSceneStateChangeHandler}
            toggleCreateAccount={this.toggleCreateAccount}
            functions={functions}
            isDeleteAcc={isDeleteAcc}
            user={user}
            subscriptionData={subscriptionData}
            random_test_modal_confirm_txt={random_test_modal_confirm_txt}
            isDeleteSuccess={isDeleteSuccess}
            cancel_subscription_txt_profile={cancel_subscription_txt_profile}
            cancel_subscription_success_txt={profile_txt_success_title}
            profile_txt_success_dec={profile_txt_success_dec}
            userSubscribedData={userSubscribedData}
            modal_txt_cancel_sub_button={profile_txt_cancel_comfirm}
            modal_txt_not_cancel_sub_button={profile_txt_cancel_denies}
            modal_txt_cancel_sub_desc={profile_txt_cancel_subscription_des}
            signOut={() => signOut()}
            logout_txt={logout_txt}
            setting_txt_settingHeader={setting_txt_settingHeader}
            location={location}
            footer_txt_profile={footer_txt_profile}
            history={history}
            photoURL={photoURL}
            name={name}
            userInfo={userInfo}
            currency={currency}
            isCheckout={isCheckout}
            btn_submit={btn_submit}
            card={userSubscribedData.card}
            card_error_empty={card_error_empty}
            card_error_invalid_cvc={card_error_invalid_cvc}
            card_error_invalid_date={card_error_invalid_date}
            card_error_number_not_correct={card_error_number_not_correct}
            card_expiration={card_expiration}
            card_number={card_number}
            email_error_empty={email_error_empty}
            email_error_wrongFormat={email_error_wrongFormat}
            handleChange={() => this.manageSceneStateChangeHandler}
            handleTouch={() => this.manageSceneStateChangeHandler}
            isLifetime={userSubscribedData.isLifetime}
            isProUser={isProUser}
            login_field_name={login_field_name}
            onReactivateSubscription={() =>
              onReactivateSubscription(
                subscriptionData,
                functions,
                isDeleteSuccess,
                user,
                this.manageSceneStateChangeHandler
              )
            }
            profile_txt_cancel_subscription={profile_txt_cancel_subscription}
            profile_txt_card_number={profile_txt_card_number}
            profile_txt_current_plan={profile_txt_current_plan}
            profile_txt_expire_date={profile_txt_expire_date}
            profile_txt_name_card={profile_txt_name_card}
            profile_txt_next_payment={profile_txt_next_payment}
            profile_txt_on={profile_txt_on}
            profile_txt_payment_method={profile_txt_payment_method}
            profile_txt_reactivate={profile_txt_reactivate}
            profile_txt_subscription_expire={profile_txt_subscription_expire}
            profile_txt_visa_expire={profile_txt_visa_expire}
            purchase_button_subscribe_now={purchase_button_subscribe_now}
            purchase_txt_benefit_details_01={purchase_txt_benefit_details_01}
            shop_txt_7_day_trial={shop_txt_7_day_trial}
            update_subscription_methob_txt_profile={
              update_subscription_methob_txt_profile
            }
            validCardCvc={validCardCvc}
            validCardExpiry={validCardExpiry}
            validCardNumber={validCardNumber}
            tracker={this.props.tracker}
          />
        )}
      </Fragment>
    );
  }
}

export const mapStateToProps = (state: Object) => {
  const { data, game, storage } = state;
  const { nativeLangCode, targetLangCode } = data;
  const { user } = storage;
  const unitObject = game.key.split(',');
  return {
    nativeLangCode,
    targetLangCode,
    unitObject,
    user: user
  };
};

export default connect(mapStateToProps)(ManageSubscriptionScene);
