/* @flow */
import React, { Fragment } from 'react';

import styled, { keyframes } from 'styled-components';
import Analytic from '../../util/Analytic';
import { LingProgressBar } from './LingProgressBar';
import { Box } from '@mui/material';
import { PurchaseLoginModal } from '../../shop/components/main-components/PurchaseLoginModal';
import {
  PREFIX_CROWN,
  PREFIX_BOOK,
  PREFIX_LESSON,
  PREFIX_HEADER,
  LAVENDER_COLOR,
  LIGHT_GREY_3,
  LIGHT_YELLOW_2,
  DARK_YELLOW,
  WHITE,
  FIRST_TIME,
  MARIGOLD
} from '../../common/constants';

const Container = styled.div`
  width: 220px;
  margin: 0 79px;
  text-align: center;
  margin-top: 55px;
  position: relative;
`;

const UnitContent = styled.div`
  width: 220px;
  height: 162px;
  opacity: ${props => props.opacity};
  background-image: url(${props => props.url});
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  filter: ${props => `grayscale(${props.isUnitLocked ? 0.8 : 0})`};
  padding-top: 12px;
`;

const CrownContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  padding: 0 30px;
`;

const CrownImage = styled.img`
  width: 39px;
  height: 39px;
`;

const ProgressContainer = styled.div`
  display: flex;
  padding-top: 18px;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
`;

const BookImage = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

const ProgressText = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-top: 2px;
  color: ${props => (props.isCurrent ? WHITE : LIGHT_GREY_3)};
`;

const BarContainer = styled.div`
  display: flex;
  margin: 0 20px;
`;

const Title = styled.p`
  font-size: 16px;
  color: ${props => (props.isCurrent ? MARIGOLD : LIGHT_GREY_3)};
  margin-top: 23px;
  opacity: ${props => props.opacity};
`;
export const keyFrame = keyframes`
   0% { transform: rotate(0deg); }
  10% {  transform: rotate(-30deg); }
  20% {  transform: rotate(30deg); }
  30% {  transform: rotate(-40deg); }
  40% {  transform: rotate(20deg); }
  50% {  transform: rotate(0deg); }
  100% {  transform: rotate(0deg); }
`;
const HandWhite = styled.img`
  position: absolute;
  bottom: -40px;
  right: 10px;
  animation: ${keyFrame} 1.5s ease-in-out infinite;
  animation-delay: 1s;
  transform-origin: bottom center;
`;

const CrackImage = styled.img`
  position: absolute;
  height: 70%;
`;

const EggCrackImage = styled.img`
  position: absolute;
  height: 30px;
  width: 30px;
  left: 5px;
  bottom: 0;
  margin-bottom: 80px;
`;

const LockerImage = styled.img``;
const handWhite = `${PREFIX_HEADER}hand-white-60-px.png`;
const crackImage = `${PREFIX_LESSON}app_img_spacedRepetition_crackImage@3x.png`;
const eggCrack = `${PREFIX_LESSON}app_img_spacedRepetition_combinedShape@3x.png`;

const lockImage = () => (
  <LockerImage src={`${PREFIX_LESSON}lock-icon-28x28.png`} />
);

const yesCrown = () => (
  <CrownImage src={`${PREFIX_CROWN}filled-crown-icon.svg`} />
);
const noCrown = isCurrent => (
  <CrownImage
    src={
      isCurrent
        ? `${PREFIX_CROWN}crown-icon.svg`
        : `${PREFIX_CROWN}inactive-crown-icon.svg`
    }
  />
);

const renderBook = isCurrent => (
  <BookImage
    src={
      isCurrent
        ? `${PREFIX_BOOK}/book-icon.svg`
        : `${PREFIX_BOOK}/inactive-book-icon.svg`
    }
  />
);

type Props = {
  isUnitLocked: Function,
  toggleCreateAccountBusiness: Function,
  history: Object,
  unit: Object,
  unitContinue: any,
  scrollRef: Object,
  fetchUnitData: Function,
  nativeLangCode: string,
  targetLangCode: string,
  createAccount_txt_title: string,
  loginPurchase_txt: string,
  spaced_repetition_open_txt: string,
  isBusiness: boolean,
  isLogin: boolean,
  features: Object,
  isShowCrack?: boolean,
  disableRepairedUnitTooltips: Function,
  isShowPopover?: boolean
};
type State = {
  isFirstTime: boolean,
  isLoginPurchase: boolean
};

class UnitCard extends React.Component<Props, State> {
  child: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      isFirstTime: false,
      isLoginPurchase: false
    };
  }
  goToUnit(unitId: number) {
    const { fetchUnitData, nativeLangCode, targetLangCode } = this.props;
    fetchUnitData(targetLangCode, nativeLangCode, unitId, () => {
      this.props.history.push(`/unit/${unitId}`);
    });
  }
  UNSAFE_componentWillMount() {
    this.setState({
      // eslint-disable-next-line
      isFirstTime: localStorage.getItem(FIRST_TIME) === 'true'
    });
  }
  toggleLoginPurchase = () => {
    this.setState({
      isLoginPurchase: !this.state.isLoginPurchase
    });
  };
  toggleCreateAccountBusiness = () => {
    this.toggleLoginPurchase();
    this.props.toggleCreateAccountBusiness(42);
  };
  stateChangeHandler = (name: string, value: any) => {
    this.setState({
      [name]: value
    });
  };
  render() {
    const { isFirstTime } = this.state;
    const {
      unit,
      unitContinue,
      isUnitLocked,
      isBusiness,
      createAccount_txt_title,
      loginPurchase_txt,
      isLogin,
      features
    } = this.props;

    const { hasSpeaking, hasWriting } = features;
    const { image, crowns, key, progress, goal, title } = unit;
    const isCurrent = unitContinue === key;
    const { scrollRef, isShowCrack, disableRepairedUnitTooltips } = this.props;
    const unitId = unit.key;
    let opacity, isDisable;
    if (isUnitLocked(unitId)) {
      opacity = 0.5;
      isDisable = true;
    } else {
      opacity = 1;
      isDisable = false;
    }

    const styles = {
      container: {
        position: 'relative',
        width: '100%',
        height: 249,
        borderRadius: 4,
        border: `solid 3px ${isCurrent ? '#ffcc00' : LAVENDER_COLOR}`, // TODO: attach to theme color
        backgroundColor: isCurrent ? '#ffcc00' : LAVENDER_COLOR,
        cursor: 'pointer',
        '&:hover': {
          border: `solid 3px ${isDisable ? 'inherit' : '#fc0'}`
        }
      }
    };

    let goalUnit = 0;
    if (hasSpeaking) {
      if (hasWriting) {
        if (goal > 7) {
          goalUnit = 7;
        } else {
          goalUnit = goal;
        }
      } else {
        goalUnit = 6;
      }
    } else {
      if (hasWriting) {
        goalUnit = 6;
      } else {
        goalUnit = 5;
      }
    }

    return (
      <Fragment>
        <Container id={key} ref={scrollRef}>
          <Box
            sx={styles.container}
            id={'home'}
            disabled={isDisable}
            opacity={opacity}
            isCurrent={isCurrent}
            onClick={() => {
              if (!isDisable) {
                if (!isLogin && isBusiness && unitId === 42) {
                  this.toggleLoginPurchase();
                } else {
                  const unitId = unit.key;
                  if (isShowCrack) {
                    disableRepairedUnitTooltips();
                  }
                  this.goToUnit(unitId);
                  Analytic.logGA(Analytic.key.U2, {
                    category: Analytic.key.U2,
                    action: 'Click'
                  });
                }
              } else {
                this.props.history.push('/shop', { source: 'locked_unit' });
              }
            }}
          >
            {isFirstTime && unitId === 1 ? (
              <HandWhite src={handWhite} alt={handWhite} />
            ) : null}

            {isShowCrack && <EggCrackImage src={eggCrack} alt="eggCrack" />}
            {isShowCrack && <CrackImage src={crackImage} alt="crackImage" />}
            <UnitContent
              url={image}
              isUnitLocked={isUnitLocked(unitId)}
              opacity={opacity}
            >
              <CrownContainer>
                {crowns >= 1 ? yesCrown() : noCrown(isCurrent)}
                {crowns >= 2 ? yesCrown() : noCrown(isCurrent)}
                {crowns >= 3 ? yesCrown() : noCrown(isCurrent)}
              </CrownContainer>
            </UnitContent>
            <ProgressContainer>
              {renderBook(isCurrent)}
              <ProgressText
                isCurrent={isCurrent}
              >{`${progress}/${goalUnit}`}</ProgressText>
            </ProgressContainer>
            <BarContainer>
              <LingProgressBar
                backgroundColor={isCurrent ? WHITE : LIGHT_GREY_3}
                color={isCurrent ? LIGHT_YELLOW_2 : DARK_YELLOW}
                progress={progress}
                progressMax={goalUnit}
              />
            </BarContainer>
          </Box>
          <Title isCurrent={isCurrent} opacity={opacity}>
            {' '}
            {isDisable ? lockImage() : null}
            {title}
          </Title>

          {/* {true && <Triangle />} */}
        </Container>
        <PurchaseLoginModal
          createAccount_txt_title={createAccount_txt_title}
          loginPurchase_txt={loginPurchase_txt}
          isLoginPurchase={this.state.isLoginPurchase}
          manageSceneStateChangeHandler={this.stateChangeHandler}
          toggleCreateAccount={this.toggleCreateAccountBusiness}
        />
      </Fragment>
    );
  }
}

export { UnitCard };
